import React, { useEffect, useState } from "react";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

function MoviesPage({ onReset, sendData }) {
  //const [searchQuery, setSearchQuery] = useState("");
  const [cardData, setCardData] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://13.229.132.35:8000/recommendations",
        sendData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const sortedRecommendations = response.data?.recommendations.sort(
        (a, b) => {
          // Move LLM_MATCH to the beginning
          if (
            a.element.recommendation_type === "LLM_MATCH" &&
            b.element.recommendation_type !== "LLM_MATCH"
          ) {
            return -1;
          } else if (
            a.element.recommendation_type !== "LLM_MATCH" &&
            b.element.recommendation_type === "LLM_MATCH"
          ) {
            return 1;
          } else {
            return 0;
          }
        }
      );

      setCardData(sortedRecommendations);
      setSelectedMovie(sortedRecommendations[0]);
    } catch (error) {
      console.log(error); // Handle error
    }
    setLoading(false);
  };

  const handleResetClick = () => {
    onReset();
  };

  const handleCardClick = (movie) => {
    setSelectedMovie(movie);
  };

  useEffect(() => {
    fetchData();
  }, [sendData]);

  const [selectedMovie, setSelectedMovie] = useState(cardData[0]);

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  // Chunk the card data into arrays containing four cards each
  const chunkedCardData = chunkArray(cardData, 4);

  return (
    <>
      <div className="App-header">
        <Row style={{ marginLeft: "2%", marginRight: "2%" }}>
          <div className="logo">
            <img src="saranyu_logo.png" alt="logo" className="logo" />
          </div>
          <div className="page-title" style={{ marginTop: "30px" }}>
            <span
              style={{
                color: "#f6f6f6",
              }}
            >
              Content Recommendation System
            </span>
          </div>
        </Row>
        <Row
          className="justify-content-left"
          style={{ marginTop: "2%", marginLeft: "2%", marginRight: "2%" }}
        >
          <Col className="col-8" style={{ alignContent: "right" }}>
            <CardTitle
              tag="h5"
              style={{
                position: "sticky",
                border: "1px solid #374352",
                top: "0px", // Adjust as needed
                backgroundColor: "#2A3038", // Background color of sticky div
                paddingRight: "20px", // Padding of sticky div
                paddingTop: "20px", // Padding of sticky div
                //paddingBottom: "20px", // Padding of sticky div
                zIndex: "100", // Ensures the sticky div stays above other content
              }}
              className="card-title-btn"
            >
              LLM Search Results
              {/* <div className="d-flex justify-content-center"> */}
              <Button onClick={handleResetClick} className="search-btn-reset">
                Reset
              </Button>
              {/* </div> */}
            </CardTitle>
            <Card
              style={{
                background: "#2A3038",
                color: "#fff",
                maxHeight: "70vh",
                overflowY: "auto",
                height: "70vh",
                border: "1px solid #374352",
                borderRadius: "8px",
                borderTop: "0px solid #2A3038",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0",
              }}
              className="custom-scrollbar"
            >
              <CardBody>
                <CardGroup>
                  <>
                    {loading ? (
                      <div className="col-12 d-flex justify-content-center mt-5">
                        <ScaleLoader
                          style={{
                            width: "3rem",
                            height: "3rem",
                            marginTop: "2rem",
                          }}
                          color="white"
                        />
                      </div>
                    ) : (
                      <div>
                        {chunkedCardData.map((row, index) => (
                          <Row key={index} className="mb-3">
                            {row.map((movie, cardIndex) => (
                              <Col key={cardIndex} className="col-3">
                                <Card
                                  style={{
                                    background: "#2A3038",
                                    color: "#fff",
                                    maxHeight: "420px",
                                    overflowY: "auto",
                                    cursor: "pointer",
                                    border: "1px solid #2A3038",
                                  }}
                                  className={`custom-scrollbar equal-height ${
                                    selectedMovie === movie ? "active-card" : ""
                                  }`}
                                  onClick={() => {
                                    handleCardClick(movie);
                                  }} // Handle click event
                                >
                                  <CardBody
                                    className="card-content custom-scrollbar"
                                    style={{ padding: "8px" }}
                                  >
                                    <CardImg
                                      src={movie?.element.cover_art_url}
                                      alt="Movie Poster"
                                      className="mb-2 thumbnail-list mx-auto d-block" // Center img for reduced size mx-auto d-block
                                    />
                                    <div
                                      style={{
                                        display: "inline-block",
                                        paddingLeft: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline",
                                        }}
                                        className="movie-title-list"
                                      >
                                        {movie?.element.title}
                                      </span>
                                      <br />
                                      <span
                                        className="movie-title-metadata"
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          WebkitLineClamp: 1, // Limiting to 3 lines
                                        }}
                                      >
                                        {movie?.element.year_of_release} ‧{" "}
                                        {movie?.element.genres.join(", ")}
                                      </span>
                                    </div>{" "}
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </div>
                    )}
                  </>
                </CardGroup>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-4">
            <CardTitle
              tag="h5"
              style={{
                position: "sticky",
                border: "1px solid #374352",
                borderBottom: "0px solid #2A3038",
                top: "0px", // Adjust as needed
                backgroundColor: "#2A3038", // Background color of sticky div
                paddingRight: "20px", // Padding of sticky div
                paddingTop: "20px", // Padding of sticky div
                //paddingBottom: "20px", // Padding of sticky div
                zIndex: "100", // Ensures the sticky div stays above other content
              }}
              className="card-title-btn"
            >
              Selected Movies Details
            </CardTitle>
            <Card
              style={{
                background: "#2A3038",
                color: "#fff",
                maxHeight: "70vh",
                overflowY: "auto",
                height: "70vh",
                border: "1px solid #374352",
                borderRadius: "8px",
                borderTop: "0px solid #2A3038",
                borderBottom: "1px solid #374352",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0",
                padding: "16px",
                paddingTop: "10px",
              }}
              className="custom-scrollbar"
            >
              <>
                {loading ? (
                  <div className="d-flex justify-content-center mt-5">
                    <ScaleLoader
                      style={{
                        width: "3rem",
                        height: "3rem",
                        marginRight: "2rem",
                        marginTop: "3rem",
                      }}
                      color="white"
                    />
                  </div>
                ) : (
                  <>
                    {selectedMovie && (
                      <>
                        <CardBody style={{ paddingLeft: "0" }}>
                          {" "}
                          {/* className="custom-scrollbar card-content-details"  */}
                          <CardImg
                            src={selectedMovie?.element.cover_art_url}
                            alt="Movie Poster"
                            className="mb-3 thumbnail mx-auto d-block" //Center img using this mx-auto d-block
                          />
                          {selectedMovie.element.reason && (
                            <>
                              <div
                                style={{
                                  background: "#65656B",
                                  padding: "8px",
                                  borderRadius: "5px",
                                }}
                              >
                                <span className="contains">
                                  <img
                                    src="ai-chip.png"
                                    alt="reason"
                                    width="20px"
                                    height="20px"
                                  />{" "}
                                  <CardText
                                    className="cast-details"
                                    style={{
                                      display: "inline",
                                      textAlign: "left",
                                      fontStyle: "normal",
                                      fontVariant: "normal",
                                      fontWeight: "500", // 'medium' corresponds to '500'
                                      fontSize: "14px",
                                      lineHeight: "21px",
                                      fontFamily: "Montserrat",
                                      letterSpacing: "0.29px",
                                      color: "#E1E1E1",
                                      opacity: 1,
                                    }}
                                  >
                                    {selectedMovie?.element?.reason}
                                  </CardText>
                                </span>
                              </div>
                              <br />
                            </>
                          )}
                          <CardTitle
                            style={{
                              textAlign: "left",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "500", // 'medium' corresponds to '500'
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontFamily: "Montserrat",
                              letterSpacing: "0.29px",
                              color: "#E1E1E1",
                              opacity: 1,
                            }}
                          >
                            {selectedMovie?.element.title}
                          </CardTitle>
                          <CardSubtitle
                            className="mb-2"
                            tag="h6"
                            style={{
                              textAlign: "left",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "500", // 'medium' corresponds to '500'
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontFamily: "Montserrat",
                              letterSpacing: "0.22px",
                              color: "#A7A6A4",
                              opacity: 1,
                            }}
                          >
                            {selectedMovie?.element.year_of_release} ‧{" "}
                            {selectedMovie?.element.genres.join(", ")} ‧{" "}
                            {selectedMovie?.element.rating}
                          </CardSubtitle>
                          <span
                            className="contains"
                            style={{
                              textAlign: "left",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "500", // 'medium' corresponds to '500'
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontFamily: "Montserrat",
                              letterSpacing: "0.29px",
                              color: "#E1E1E1",
                              opacity: 1,
                            }}
                          >
                            <CardText className="field-title">
                              {/* Recommendation Type:{" "} */}
                              <i className="fa fa-film" aria-hidden="true"></i>{" "}
                              &nbsp;
                              <i className="fa fa-thumbs-up"></i>&nbsp;:{" "}
                            </CardText>
                            <CardText
                              className="cast-details"
                              style={{ display: "inline" }}
                            >
                              {/* <CardSubtitle className="mb-2 text-muted" tag="h6"> */}
                              {selectedMovie?.element.recommendation_type}
                              {/* </CardSubtitle> */}
                            </CardText>
                          </span>
                          <br />
                          <br />
                          <span
                            className="contains"
                            style={{
                              textAlign: "left",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "500", // 'medium' corresponds to '500'
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontFamily: "Montserrat",
                              letterSpacing: "0.29px",
                              color: "#E1E1E1",
                              opacity: 1,
                            }}
                          >
                            <CardText className="field-title">Cast: </CardText>
                            <CardText
                              className="cast-details"
                              style={{ display: "inline" }}
                            >
                              {/* <CardSubtitle className="mb-2 text-muted" tag="h6"> */}
                              {selectedMovie?.element.cast.join(", ")}
                              {/* </CardSubtitle> */}
                            </CardText>
                          </span>
                          <br />
                          <br />
                          <span
                            className="contains"
                            style={{
                              textAlign: "left",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "500", // 'medium' corresponds to '500'
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontFamily: "Montserrat",
                              letterSpacing: "0.29px",
                              color: "#E1E1E1",
                              opacity: 1,
                            }}
                          >
                            <CardText className="field-title">
                              Description:{" "}
                            </CardText>
                            <CardText
                              className="cast-details"
                              style={{ display: "inline" }}
                            >
                              {/* <CardSubtitle className="mb-2 text-muted" tag="h6"> */}
                              {selectedMovie?.element.summary}
                              {/* </CardSubtitle> */}
                            </CardText>
                          </span>
                        </CardBody>
                      </>
                    )}
                  </>
                )}
              </>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MoviesPage;
