export const watchHistory = [
  {
    user_id: "0aa2fb9a-58b3-4211-b5ee-962f134a4479",
    item_data: [
      {
        content_id: "tt6836936",
        percentage_watched: 69,
      },
      {
        content_id: "tt1612573",
        percentage_watched: 24,
      },
      {
        content_id: "tt1562872",
        percentage_watched: 61,
      },
      {
        content_id: "tt6712014",
        percentage_watched: 77,
      },
      {
        content_id: "tt2675978",
        percentage_watched: 58,
      },
    ],
  },
  {
    user_id: "4a2dc276-7ca6-4279-a467-1af685a51b95",
    item_data: [
      {
        content_id: "tt7919680",
        percentage_watched: 82,
      },
      {
        content_id: "tt4541102",
        percentage_watched: 23,
      },
      {
        content_id: "tt1784589",
        percentage_watched: 34,
      },
      {
        content_id: "tt2461132",
        percentage_watched: 31,
      },
      {
        content_id: "tt1787988",
        percentage_watched: 70,
      },
    ],
  },
  {
    user_id: "a62aba82-5fd0-4d96-8f8a-ceb1368e2904",
    item_data: [
      {
        content_id: "tt2246595",
        percentage_watched: 99,
      },
      {
        content_id: "tt1773764",
        percentage_watched: 48,
      },
      {
        content_id: "tt2424988",
        percentage_watched: 9,
      },
      {
        content_id: "tt3380264",
        percentage_watched: 64,
      },
      {
        content_id: "tt5756052",
        percentage_watched: 29,
      },
    ],
  },
  {
    user_id: "28db50b7-e8a6-415d-8854-a54f9d0a7886",
    item_data: [
      {
        content_id: "tt5928576",
        percentage_watched: 29,
      },
      {
        content_id: "tt5617172",
        percentage_watched: 2,
      },
      {
        content_id: "tt5755606",
        percentage_watched: 43,
      },
      {
        content_id: "tt2708644",
        percentage_watched: 96,
      },
      {
        content_id: "tt3886444",
        percentage_watched: 83,
      },
    ],
  },
  {
    user_id: "5bb771af-b15e-4b8e-9af3-5fa9721b7e91",
    item_data: [
      {
        content_id: "tt1183917",
        percentage_watched: 63,
      },
      {
        content_id: "tt4940456",
        percentage_watched: 57,
      },
      {
        content_id: "tt2872050",
        percentage_watched: 82,
      },
      {
        content_id: "tt8291224",
        percentage_watched: 28,
      },
      {
        content_id: "tt1839596",
        percentage_watched: 18,
      },
    ],
  },
  {
    user_id: "90c466e6-bdf4-4075-b5ec-84da6609e250",
    item_data: [
      {
        content_id: "tt3501994",
        percentage_watched: 71,
      },
      {
        content_id: "tt7154994",
        percentage_watched: 32,
      },
      {
        content_id: "tt4107858",
        percentage_watched: 58,
      },
      {
        content_id: "tt4430212",
        percentage_watched: 43,
      },
      {
        content_id: "tt1627924",
        percentage_watched: 9,
      },
    ],
  },
  {
    user_id: "65044cbe-a456-473a-8b3e-16535d06f9e7",
    item_data: [
      {
        content_id: "tt1704680",
        percentage_watched: 20,
      },
      {
        content_id: "tt3224288",
        percentage_watched: 92,
      },
      {
        content_id: "tt1773764",
        percentage_watched: 32,
      },
      {
        content_id: "tt9378950",
        percentage_watched: 70,
      },
      {
        content_id: "tt4610308",
        percentage_watched: 71,
      },
    ],
  },
  {
    user_id: "b1bc5a37-e64a-4a23-b0a1-a3d2c351615c",
    item_data: [
      {
        content_id: "tt2762334",
        percentage_watched: 16,
      },
      {
        content_id: "tt2283894",
        percentage_watched: 14,
      },
      {
        content_id: "tt3431756",
        percentage_watched: 90,
      },
      {
        content_id: "tt1396208",
        percentage_watched: 98,
      },
      {
        content_id: "tt2187972",
        percentage_watched: 100,
      },
    ],
  },
];

export const watchHistoryContentIDS = Array.from(
  new Set(
    watchHistory.flatMap((user) =>
      user.item_data.map((item) => item.content_id)
    )
  )
);
