export const searchHistoryData = [
  {
    "content_id": "tt8291224",
    "title": "Uri: The Surgical Strike",
    "summary": "Divided over five chapters  the film chronicles the events of the surgical strike conducted by the Indian military against suspected militants in Pakistan occupied Kashmir. It tells the story of the 11 tumultuous events over which the operation was carried out. Indian army special forces carry out a covert operation to avenge the killing of fellow army men at their base by a terrorist group.",
    "year_of_release": 2019,
    "runtime": 138,
    "genres": "Action|Drama|War",
    "rating": 8.4,
    "cast": "Vicky Kaushal|Paresh Rawal|Mohit Raina|Yami Gautam|Kirti Kulhari|Rajit Kapoor|Ivan Rodrigues|Manasi Parekh|Swaroop Sampat|Riva Arora|Yogesh Soman|Fareed Ahmed|Akashdeep Arora|Kallol Banerjee|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMWU4ZjNlNTQtOGE2MS00NDI0LWFlYjMtMmY3ZWVkMjJkNGRmXkEyXkFqcGdeQXVyNjE1OTQ0NjA@.jpg"
  },
  {
    "content_id": "tt8785426",
    "title": "Jabariya Jodi",
    "summary": "A thug helps kidnap grooms in order to avoid paying out dowries for their upcoming marriage.",
    "year_of_release": 2019,
    "runtime": 125,
    "genres": "Action|Comedy|Drama",
    "rating": 4.2,
    "cast": "Sidharth Malhotra|Parineeti Chopra|Javed Jaffrey|Sanjay Mishra|Aparshakti Khurana|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BOGUzOTI2MDMtNWJjOS00MWQ5LTg5ZWQtNjIyN2QzNWQxODhhXkEyXkFqcGdeQXVyOTAzMTc2MjA@.jpg"
  },
  {
    "content_id": "tt6826438",
    "title": "Parmanu: The Story of Pokhran",
    "summary": "Captain Ashwat Raina's efforts to turn India into a nuclear state are at first met with criticism and then years later  with an offer from the government. With the help of Himanshu Shukla  the Prime Minister's principal secretary  he leads a team to conduct a second nuclear test series. The only major threat lurking is the American Lacrosse satellite which caught the first test preparations  further causing America to warn India about such future tests. With their patriotic hearts and unbreakable spirits  Ashwat and his team race against time to conduct the tests confidentially while the country's worsening political scenario as well as the presence of spies from US and Pakistan puts them into both mental as well as physical challenges.",
    "year_of_release": 2018,
    "runtime": 129,
    "genres": "Action|Drama|History",
    "rating": 7.7,
    "cast": "John Abraham|Boman Irani|Diana Penty|Anuja Sathe|Vikas Kumar|Yogendra Tiku|Aditya Hitkari|Ajay Shankar|Darshan Pandya|Mark Bennington|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMDNjZjkyNjQtNWMyMC00ODA5LTgyODctOGRiOWUwYTAzOWVjXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt8202612",
    "title": "Satyameva Jayate (2018 film)",
    "summary": "There are about 2 Lakh policemen in Mumbai. Tasked with the safety of this enormous city  a majority of them don't necessarily have the \"cleanest\" of vardis. When four such police inspectors are burnt alive in a series of merciless killings  the entire police force is rocked with terror. The media is looking for answers. The public is on the fence over these killings. These crimes reflect Vir as a person. Bold  Calculating  and Unapologetic. He's not scared of killing  or dying. It was clear that he had an agenda. A reason. A drive; behind these deaths and he was far from done. Fearing the next attack  the Commissioner calls upon their best bet to catch Vir. DCP Shivansh is what a proper policeman looks like. Strong  Courageous  Patriotic and most importantly  \"Clean.\" And of course  he's the best at what he does. What follows is a succession of events that will test his Probity to the fullest. He is up against Vir  who is a vigilante and will stop at nothing to complete his mission. ...",
    "year_of_release": 2018,
    "runtime": 140,
    "genres": "Action|Thriller",
    "rating": 5.6,
    "cast": "John Abraham|Manoj Bajpayee|Aisha Sharma|Amruta Khanvilkar|Manish Chaudhary|Myrah Dandekar|Devdutta Nage|Nora Fatehi|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYzYxNGI3MmYtMWQyMi00NmViLWE0ZWQtNWM5ZTY3NTIxNzU0XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt7722258",
    "title": "Genius (2018 Hindi film)",
    "summary": "An orphan  with a brilliant mind  joins RAW and is burdened by the killing of his entire team. Until he doesn't avenge their death  he can't live in peace with his love. So  he hatches a genius plan.",
    "year_of_release": 2018,
    "runtime": 165,
    "genres": "Action|Thriller",
    "rating": 4.6,
    "cast": "Utkarsh Sharma|Ishita Chauhan|Mithun Chakraborty|Ayesha Jhulka|Nawazuddin Siddiqui|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTQ5NDA4Y2EtNjYyNC00MDExLWIxYWMtMDgxMjFlODIyMTU1XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt7526836",
    "title": "FryDay",
    "summary": "Rajiv  a struggling water purifier salesman  after a long chase  is able to bag a client Gagan Kapoor and fix him for an appointment on a Friday. However  he finds himself caught up in a tricky situation when he arrives at Kapoor's house and catches Kapoor cheating on his wife Bela with another woman Bindu.",
    "year_of_release": 2018,
    "runtime": 114,
    "genres": "Comedy",
    "rating": 5.4,
    "cast": "Govinda|Varun Sharma|Sanjay Mishra|Rajesh Sharma|Digangana Suryavanshi|Prabhleen Sandhu|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNmRmM2QwZmMtNmZiNC00Mzc5LTlmZjktMzMzZDI2NTQwYzhjXkEyXkFqcGdeQXVyNjE1OTQ0NjA@.jpg"
  },
  {
    "content_id": "tt5502766",
    "title": "Phillauri (film)",
    "summary": "A young Mangalik boy is supposed to marry a tree if he wants to marry a girl of his choice while the tree which he gets married has Shashi a ghost residing on it waiting for her lover.Shashi starts to follow him everywhere after he marries the tree.",
    "year_of_release": 2017,
    "runtime": 138,
    "genres": "Comedy|Drama|Fantasy",
    "rating": 6.1,
    "cast": "Anushka Sharma|Diljit Dosanjh|Suraj Sharma|Mehreen Pirzada|Manav Vij|Salima Raza|Raza Murad|Abhishek Banerjee|Nidhi Bisht|Sunil Mehra|Suparna Marwah|Hobby Dhaliwal|Shabnam Vadhera|Hassan Saad|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNjM1NDYyOGItZGYzNi00ZDkwLWFiMDItYTAzYWQ0YjZhMDIyXkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt6170954",
    "title": "Naam Shabana",
    "summary": "Shabana a college student who is also Kudo fighter lives with her mother in Mumbai at a young age she had killed her father trying to save her mother from his daily abuse and was sent to children remand home. Shabana is in love with Jai who gets killed by some spoiled brats when he tries to save Shabana from their abuse. Three months after the death of Jai the police haven't made any arrest as they were trying to save the culprits. Shabana then gets a anonymous call from Ranvir Singh telling that he will provide her details of Jai's killers in return she has to work for their secret National Defense Force.",
    "year_of_release": 2017,
    "runtime": 147,
    "genres": "Action|Thriller",
    "rating": 6.3,
    "cast": "Taapsee Pannu|Akshay Kumar|Manoj Bajpayee|Taher Shabbir|Virendra Saxena|Prithviraj Sukumaran|Danny Denzongpa|Madhurima Tuli|Murli Sharma|Anupam Kher|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTkxMDk1Mjc2NF5BMl5BanBnXkFtZTgwNjk4Njk3MTI@.jpg"
  },
  {
    "content_id": "tt6642396",
    "title": "Jattu Engineer",
    "summary": "A teacher works hard to better a village that has poverty  unemployment  and a drug problem.",
    "year_of_release": 2017,
    "runtime": 139,
    "genres": "Comedy",
    "rating": 6,
    "cast": "Amardeep Insan|Ashok Insan|Divya Insan|Ishwar Insan|Jaswinder Insan|Manoj Insan|Rajesh Insan|Rakesh Insan|Raman Insan|Randhir Insan|Satish Insan|Satyawan Insan|Sukhottam Insan|Vishu Insan|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTU1OTA3NTQ2MV5BMl5BanBnXkFtZTgwNjQzOTcwMjI@.jpg"
  },
  {
    "content_id": "tt4640206",
    "title": "Sachin: A Billion Dreams",
    "summary": "A tale of a small boy with dreams and his journey to becoming the God of Cricket and the most celebrated sportsperson in his country.",
    "year_of_release": 2017,
    "runtime": 138,
    "genres": "Documentary|Sport",
    "rating": 8.6,
    "cast": "Sachin Tendulkar|Mayur More|Mayuresh Pem|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZmY2M2I5ZGMtNWZjYy00MmI5LWI1NjgtMTY5NTY5NDFhZGRiXkEyXkFqcGdeQXVyNjc5MTcwMjQ@.jpg"
  },
  {
    "content_id": "tt3726012",
    "title": "Mastizaade",
    "summary": "Two womanizing bachelors fall in love with twin sisters who run a sex addiction clinic.",
    "year_of_release": 2016,
    "runtime": 119,
    "genres": "Comedy",
    "rating": 2.3,
    "cast": "Tusshar Kapoor|Vir Das|Sunny Leone|Asrani|Shaad Randhawa|Suresh Menon|Sushmita Mukherjee|Riteish Deshmukh|Harshada Kulkarni|Rina Charaniya|Vivek Vaswani|Karan Dewani|Kurush Deboo|Munish Makhija|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMzk0NjIxMjg2NV5BMl5BanBnXkFtZTgwMDk5NzY2NzE@.jpg"
  },
  {
    "content_id": "tt3840534",
    "title": "Junooniyat",
    "summary": "Captain Jahan Bakshi rescues a Punjabi girl  Suhani. This starts a friendship that evolves into a passionate love affair.",
    "year_of_release": 2016,
    "runtime": 155,
    "genres": "Drama|Romance",
    "rating": 4.2,
    "cast": "Pulkit Samrat|Yami Gautam|Gulshan Devaiah|Hrishitaa Bhatt|S. Poonam Kaur|Aneesha Joshi|Herry Tangiri|Khushboo Upadhyay|Sanjay Mehandiratta|Charu Rohatgi|Shakeel Khan|Ravi Khemu|Anoop Kamal Singh|Madhuri D. Bandiwedkar|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BOGUwYjIxNzYtODA4Ni00YzExLWE3NDctZDlmZGJkNWIwNzU3XkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt3859980",
    "title": "Mohenjo Daro (film)",
    "summary": "In 2016 BC Sarman is a farmer in Amri and wants to trade in Mohenjo Daro to.Durjan Sarman 's uncle protests against it but cant stop Sarman going Mohenjo Daro  Durjan tells him to be careful as the city is harsh and selfish.Sarman reaches Mohenjo Daro and feels that somehow the place is connected to him The city is ruled by evil Maham and his son Moonja Sarman saves Chaani from being stamped by horses and they both start liking each other but Chaani is about to marry Moonja on their wedding day a major fight happens between Sarman and Moonja Maham challenges Sarman to take up a fight against Bakar-Zokhar Sarman proposes Maham that if he wins Chaani should be his will Sarman win Chaani and what is Sarman 's connection with Mohenjo Daro.",
    "year_of_release": 2016,
    "runtime": 155,
    "genres": "Action|Adventure|Drama",
    "rating": 5.7,
    "cast": "Hrithik Roshan|Pooja Hegde|Kabir Bedi|Arunoday Singh|Suhasini Mulay|Nitish Bharadwaj|Kishori Shahane|Sharad Kelkar|Manish Chaudhary|Narendra Jha|Shaji Chaudhary|Tufail Khan Rigoo|Diganta Hazarika|Naina Trivedi|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNWZiZGFiYjMtMmJhYS00ZTMzLThlNGQtYjVhMjY0MjMwZjVjXkEyXkFqcGdeQXVyNTM3NDI3MzQ@.jpg"
  },
  {
    "content_id": "tt3337550",
    "title": "Rahasya",
    "summary": "A Doctor stands accused of killing his own eighteen-year old daughter. While this aggrieved father protests his innocence  all evidence points towards him irrefutably. While his own wife suspects him too  the C.B.I. sleuth on the case concludes that the doctor could not have killed his own daughter. So then  who did it? And why? As the C.B.I sleuth seeks these answers  he uncovers a vicious web of adultery and hidden family secrets...",
    "year_of_release": 2015,
    "runtime": 123,
    "genres": "Mystery|Thriller",
    "rating": 7.6,
    "cast": "Kay Kay Menon|Ashish Vidyarthi|Tisca Chopra|Mita Vasisht|Ashwini Kalsekar|Kakar Vinit|Jitendra Barsiwal|Farida Dadi|Ramesh Deo|Usha Jadhav|Bikramjeet Kanwarpal|Murari Kumar|Aarush Maurya|Nimai Bali|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMGY2NzA3OGQtNTIyNy00ZTcwLTgzOGQtOGJjM2FhMGEwODg1XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3836958",
    "title": "Shamitabh",
    "summary": "The film revolves around a very unique plot  where it shows two different individuals having different talents and when they become ONE  the mixture measures up to be a very big hit in the industry. But in the meantime  EGO comes in the way and prepares to shatter the bond into pieces. Dhanush is a mute aspiring actor from a small village but with great acting talents. Amitabh has a very impactful voice. Both  on their own  can not achieve any greatness in the film industry. But with a help of a third party (Aksara Hassan)  when they come together  the talents combined  make a complete new Artist. Dhanush becomes the actor onscreen and Amitabh becomes his Voice. But after reaching the peak of success  Both the leads start to become miserable to the question of having more importance in the unique cocktail. An unstoppable fight of ego comes in action And the clash drifts them apart  destroying every greatness they achieved and every possible milestones they could build together.",
    "year_of_release": 2015,
    "runtime": 155,
    "genres": "Drama",
    "rating": 6.8,
    "cast": "Amitabh Bachchan|Dhanush|Akshara Haasan|Abhinaya|Pete Meads|Rukmini Vijayakumar|Shubham|Pratik|Sonamani|Rajeev|Ajay Jadhav|Asif Ali Beg|Ivan Rodrigues|Uday Tikekar|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZmExOWZiMDYtNmNlNy00OGQ3LTlkNGUtNTQyODdiMjg4ZmZkXkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt2976182",
    "title": "Roy (film)",
    "summary": "Kabir Grewal  a Casanovic film maker and screenwriter is making multiple films (GUNS Trilogy) based on a thief's life and robberies  all achieving high success. In order to shoot his third installment (GUNS 3)  he goes to Malaysia  where he meets a London based film-maker Ayesha Aamir . Kabir and Ayesha get friendly and soon fall in love. When Ayesha gets to know about Kabir's casanova attitude  she breaks up with him and returns to London. Dejected Kabir goes into depression and returns back to Mumbai  leaving his film incomplete. After several failed trials Kabir couldn't find a perfect climax for his story. On his assistant Meera's suggestion Kabir goes to a film festival as part of the jury  where Ayesha's film is being screened. Ayesha  thought that Kabir is following her and asks him to stay away from her life. After his father's death and been sued by film financiers Kabir decides to move on and complete his film. In a parallel story  An infamous Roy is a mysterious ...",
    "year_of_release": 2015,
    "runtime": 146,
    "genres": "Action|Drama|Mystery",
    "rating": 3.3,
    "cast": "Arjun Rampal|Jacqueline Fernandez|Ranbir Kapoor|Barun Chanda|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMjEzOTE2NDgxN15BMl5BanBnXkFtZTgwNzk1MTQ1MTE@.jpg"
  },
  {
    "content_id": "tt4556848",
    "title": "Barkhaa",
    "summary": "Follows the relationship between two young minds  which took a unfavorable turn.",
    "year_of_release": 2015,
    "runtime": 127,
    "genres": "Drama|Romance",
    "rating": 5.1,
    "cast": "Sara Loren|Priyanshu Chatterjee|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZDkxNGIzNDktYWI2MS00NzdmLWI5OGEtMGIyNGVmMzZhYWQxXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt4707940",
    "title": "Miss Tanakpur Haazir Ho",
    "summary": "Miss Tanakpur is a political satire that unfolds in the backdrop of a Love-story set in a village of Haryana. In the villages of Haryana the Pradhans in general have insurmountable powers. The Pradhan of Tanakpur  Sualal Gandas is no different. Like his contemporaries  Sualal rules his village using powers vested upon him by Khap. Not just the traditional court but when time comes Sualal manages to manipulate and exploit the bigger powers of the state i.e. the Police and the Law of the land. Ms Tanakpur is Sualal's Buffalo. She had won the Beauty pageant for Buffaloes in the Local fair. She had won the trophy because she produced more milk than all other Buffaloes in the contest. Serendipity will strike in this story. Ms Tanakpur thy name is justice.",
    "year_of_release": 2015,
    "runtime": 154,
    "genres": "Comedy|Crime|Family",
    "rating": 5.4,
    "cast": "Rahul Bagga|Hrishitaa Bhatt|Brijendra Kala|Annu Kapoor|Ravi Kishan|Sanjay Mishra|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTk3NTkxNzc5N15BMl5BanBnXkFtZTgwNjY3NzM5NTE@.jpg"
  },
  {
    "content_id": "tt3449292",
    "title": "Manjhi â The Mountain Man",
    "summary": "Dashrath Manjhi was a poor man from the lowest of the low castes  living in a remote village cut off from the world by a rocky mountain range. Life for him was a daily struggle for survival. He loved his wife  Phaguniya  beyond belief. One day  while climbing the mountain to bring him food  his wife slipped  fell and died. Overwhelmed by grief  Dashrath decided to carve out a path through the mountain  so that no one else would suffer his fate. For 22 years  all by himself  with just a hammer and a chisel  he hammered away at the rocks  till the path was carved out from the mountain.",
    "year_of_release": 2015,
    "runtime": 120,
    "genres": "Adventure|Biography|Drama",
    "rating": 8,
    "cast": "Nawazuddin Siddiqui|Radhika Apte|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BODdlZTZlZTktNTYyZC00ODQ1LWE5MjYtYjc1YTQwODRlNzI5XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt5151622",
    "title": "Ranbanka",
    "summary": "Rahul  an engineer  tries his best to protect his wife from a goon feared by all. The goon wants to marry Rahul's wife at any cost  and this doesn't bother him whether she is already married or she has a son. In the process  Rahul tries to take help from the law  but all efforts go awry  and the tables turn on him. When finally everything goes against him and his wife  he turns into an angry man  who is on a rampage to protect his wife by all means  even if it means killing the goon  and giving him the worst death he can ever wish for.",
    "year_of_release": 2015,
    "runtime": 98,
    "genres": "Action",
    "rating": 3.7,
    "cast": "Manish Paul|Ravi Kishan|Pooja Thakur|Rudra Kaushik|Avya Agarwal|Avinash Dwivedi|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMWYwM2UxMDAtYTE5Zi00NWM4LTllYjMtNTQxY2JlZjM1ZWU2XkEyXkFqcGdeQXVyNjQ3ODEyMjY@.jpg"
  },
  {
    "content_id": "tt3173910",
    "title": "Hasee Toh Phasee",
    "summary": "Spread across Mumbai over a decade  the film is about the relationship between the quirky  rebellious Meeta (Parineeti Chopra) and the mischievous Nikhil (Sidharth Malhotra) struggling with their respective families to fit in. Nikhil bumps into Meeta on the occasion of her sister Deeksha's wedding while Meeta is on the run. He falls in love with Meeta's sister Karishma on the same day. The film jumps to the eve of Nikhil and Karishma's engagement. Karishma's wealthy father  Devesh Solanki  does not approve of Nikhil as he finds him to be a lackadaisical young man. On the day of his engagement  Nikhil promises Karishma that he will prove himself to be worthy of her within the next seven days. Nikhil is re-introduced to Meeta by Karishma and is instructed to put her up in a hotel. Nikhil recognizes Meeta. In an attempt to please Karishma he decides to keep Meeta in the flat above his own house  with his crazy extended family. During the course of Meeta's stay at Nikhil's house  they ...",
    "year_of_release": 2014,
    "runtime": 141,
    "genres": "Comedy|Romance",
    "rating": 6.8,
    "cast": "Sidharth Malhotra|Parineeti Chopra|Adah Sharma|Manoj Joshi|Sharat Saxena|Nina Kulkarni|Sameer Sharma|Aariyan Gupta|Amita Udgata|Anshikaa Shrivastava|Ansh Sharma|Anil Charanjeett|Bhavna Chauhan|Devika Gidwani|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTQ5NTUyMzE3Ml5BMl5BanBnXkFtZTgwOTI5MDEwMTE@.jpg"
  },
  {
    "content_id": "tt3483646",
    "title": "Bewakoofiyaan",
    "summary": "Mohit is a marketing whiz kid vying for a step up the career ladder. Mayera is a financial brain with a penchant for shoes. They're a young middle-class corporate couple that's ambitious and likes the good life too. They work hard  they party hard. They're also passionately in love with each other. Their belief: You can live on love and fresh air. Their obstacle: Mayera's willful bureaucratic father V.K Sehgal. The obstinate old man believes that only a rich man can bring Mayera happiness and a mid-level executive like Mohit simply isn't good enough! Exactly how fragile are relationships in these times where consumer lifestyles dictate their very nature and intensity? Who gets the last laugh when recession strikes and the lack of money tests love... credit-card-junkies private-sector Mohit-Mayera; or safe-playing sarkari  V.K. Sehgal?",
    "year_of_release": 2014,
    "runtime": 119,
    "genres": "Comedy|Drama|Romance",
    "rating": 5.5,
    "cast": "Ayushmann Khurrana|Sonam Kapoor|Rishi Kapoor|Swedha Singh|Deepika Amin|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BY2ZkZTdjYmItZjlkZC00OTI0LWFlMTItNGQyOGQ5YzE2M2JkXkEyXkFqcGdeQXVyNTkzNDQ4ODc@.jpg"
  },
  {
    "content_id": "tt2762334",
    "title": "Main Tera Hero",
    "summary": "Sreenath Prasad aka Seenu is the most notorious boy in Ooty. He drops out of college in Ooty in order to pursue education in a Bangalore college. Here he falls in love with Sunaina. But Sunaina is forced to marry Angad  a local police officer  who is more of a terrorizing gangster. Seenu also has another die-hard lover in the form of Ayesha  of whom he is unaware. He had once saved her friends and Ayesha instantly knew that he was the perfect man for her. Ayesha's father is a big gangster and kidnaps Sunaina to get hold of Seenu and compel him to marry his daughter.",
    "year_of_release": 2014,
    "runtime": 128,
    "genres": "Action|Comedy|Romance",
    "rating": 5.2,
    "cast": "Varun Dhawan|Ileana D'Cruz|Nargis Fakhri|Arunoday Singh|Anupam Kher|Saurabh Shukla|Daljeet Singh Gujral|Rajpal Yadav|Evelyn Sharma|Salman Khan|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZjBiYWU0ZmMtZDE0NC00ZTUxLWE3OTQtY2YyNjVjMTRlOGJjXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3142232",
    "title": "Heropanti",
    "summary": "Rising from present India in the conflicting era of shining modernity and deep-rooted archaic traditions in the era of big city versus small towns  comes a love story sealed with an impossible fate. The story of the bold Bablu and the bratty Dimpy. Will Bablu and Dimpys love blossom under the terror of a brutal clan and the violent landscape it is set against. Heropanti is the story of today's youth  and the coming of age of its conflicting protagonists.",
    "year_of_release": 2014,
    "runtime": 146,
    "genres": "Action|Romance",
    "rating": 5.3,
    "cast": "Tiger Shroff|Kriti Sanon|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BY2M2ZmE4YTQtODhlYi00ZDc0LWJhYTAtNjkyN2E2MjI0ZTY1XkEyXkFqcGdeQXVyMTY4MjU0NDY3.jpg"
  },
  {
    "content_id": "tt3359290",
    "title": "Bobby Jasoos",
    "summary": "Bobby Jasoos starring Vidya Balan  is a film that celebrates the aspiration of Bobby  who wants to become the number one detective in the old city area of Hyderabad. The film is directed by Samar Shaikh  produced by Dia Mirza and Sahil Sangha under their banner Born Free Entertainment and is presented by Reliance Entertainment. Bobby Jasoos releases on July 4th 2014.",
    "year_of_release": 2014,
    "runtime": 121,
    "genres": "Comedy|Drama|Thriller",
    "rating": 5.5,
    "cast": "Vidya Balan|Ali Fazal|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZDRjYjQxMmYtN2Q2Zi00NzQ4LWE4MmYtM2Q4YzI1ZmM3ZGIxXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt4087770",
    "title": "Balwinder Singh Famous Ho Gaya",
    "summary": "The story of Balwinder Singh Famous Ho Gaya (2014) is two central characters Mika Singh and Shaan and the confusion they create to win the heart of the girl of their dreams because of their same name 'Balwinder Singh'.",
    "year_of_release": 2014,
    "runtime": 136,
    "genres": "Comedy",
    "rating": 3.8,
    "cast": "Asrani|Gabriela Bertante|Mikhill Chandiramani|Akram Khan|Anupam Kher|Sunny Leone|Shaan|Mika Singh|Vindu Dara Singh|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMWE3ZDRhZGEtM2UxYy00MzFlLWJlOWUtNThiZDI3NDM5OWNlXkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt2691010",
    "title": "Mumbai 125 KM",
    "summary": "On their way to a New Year's Eve party in Mumbai  a group of five friends encounter supernatural occurrences on a highway  125 kilometres outside the city.",
    "year_of_release": 2014,
    "runtime": 100,
    "genres": "Drama|Horror|Mystery",
    "rating": 2.7,
    "cast": "Karanvir Bohra|Vedita Pratap Singh|Veena Malik|Joe Debroy|Aparna Bajpai|Vije Bhatia|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZmEzMjYxYjQtZGZhZS00YzlkLWE0NzgtNzVjZDBmYzE1YTc1XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3380264",
    "title": "Kill Dil",
    "summary": "Once upon a time in North India  two killers - Dev and Tutu  roamed free. Abandoned when young and vulnerable  Bhaiyaji gave them shelter and - nurtured them to kill. All is normal in their lives until destiny throws free-spirited Disha into the mix. What follows is a game of defiance  deception and love. Played by Ranveer Singh (Dev)  Ali Zafar (Tutu)  Parineeti Chopra (Disha) and Govinda (Bhaiyaji)  Kill Dil is an entertaining thriller about guns and romance.",
    "year_of_release": 2014,
    "runtime": 127,
    "genres": "Action|Drama|Romance",
    "rating": 4.3,
    "cast": "Ranveer Singh|Ali Zafar|Parineeti Chopra|Govinda|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZWI0YmI2NmQtMzdjZS00Yzk1LTljZWQtZGE0Y2I5YmZjYWY2XkEyXkFqcGdeQXVyNTkzNDQ4ODc@.jpg"
  },
  {
    "content_id": "tt3309662",
    "title": "Jackpot (2013 film)",
    "summary": "Jackpot is a fast-paced thriller con  with sharp comic one-liners and crazy characters  set entirely in Goa against the backdrop of casino boats. The film goes back and forth in time  capturing one Goan monsoon of Full Jhol. It's a card game between the players in the con. Who will come out Aces and who will end up being the Joker?",
    "year_of_release": 2013,
    "runtime": 132,
    "genres": "Comedy|Thriller",
    "rating": 2,
    "cast": "Naseeruddin Shah|Sunny Leone|Sachiin Joshi|Bharath Niwas|Makrand Deshpande|Meenacshi Martins|Satish Gawas|George Rodrigues|Daniel Weber|Melissa Makeup|Adam Bedi|Nijanand Mawani|Deepak Walvaikar|Bosco Menez|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYjg1NDIzOTktYzI3Ny00ZjY5LTk3MTAtMDljOTkwMWMzZGEwXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3431756",
    "title": "Rakhtbeej",
    "summary": "Rakhtbeej is the story of two men  from two different generations. Even though their worlds are different their lives keep intersecting. Abhay Jatav from humble beginnings  ends up becoming the most dreaded dacoit in UP. Ajay also from humble beginings  becomes the CEO of Dabral Group of Industries  at a very young age. Abhay knows Sarita from a long time  and Sarita is in love with Abhay. But when a simple village girl Poonam enters Ahbay's life he totally forgets about Sarita. Sanjana  Dabral's daughter is in love with Ajay from a long time. But when supermodel Priya enters his life  he is blinded by her love. Abhay goes out of his way to help Maya Singh win the village elections. But Maya Singh later backstabs Abhay. Abhay loose his friends and those dear to him and this changes his fate forever. Ajay trusts Dharam  his competitor in business and love  but Dharam backstabs him. Abhay becomes a victim of Politics  Police and Dacoits  while Ajay is the victim of business rivalry. ...",
    "year_of_release": 2012,
    "runtime": 120,
    "genres": "Thriller",
    "rating": 3.1,
    "cast": "Julia Datt|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMWNkMmYyZTctNjliMC00YWJjLWI2OTAtMGM0ZjVjMWIyZjE5XkEyXkFqcGdeQXVyNDgzNDc2OTk@.jpg"
  },
  {
    "content_id": "tt1172587",
    "title": "Delhi Safari",
    "summary": "Delhi Safari is the story of a journey undertaken by a cub leopard  his mother  a monkey  a bear and a parrot when the forest they live in is on the verge of destruction. A man is on the run to destroy the forest in order to erect a balcony. These five animals plan to go to Delhi and ask the parliament some very simple yet pertinent questions - why has man become the most dangerous animal? Doesn't man understand that if the forests and the animals don't exist  man will cease to exist?",
    "year_of_release": 2012,
    "runtime": 96,
    "genres": "Animation|Comedy|Drama",
    "rating": 5.7,
    "cast": "Cary Elwes|Christopher Lloyd|Tara Strong|Troy Baker|Jane Lynch|Tom Kenny|Vanessa Williams|Brad Garrett|Jason Alexander|Roger Craig Smith|Travis Willingham|Carlos Alazraqui|Fred Tatasciore|Brian George|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMjIyOTg2NzAwM15BMl5BanBnXkFtZTcwMTUxNjU0OA@@.jpg"
  },
  {
    "content_id": "tt1363125",
    "title": "Naughty @ 40",
    "summary": "Laxminarayan Kapoor lives a wealthy lifestyle in London along with his religious wife  Maya; son Ravinder; and two nephews  Sandeep and Randeep. While the nephews have adapted themselves to the country and women  Ravinder is still a virgin at 40  and is a sleep-walker. After getting his leg pulled constantly  he asks his father to get him married  and is introduced to a statuesque beauty - who rejects him after discovering his sleep-walking disorder. His parents then get him married to Gauri  and he hopes to end his celibacy  in vain albeit  as she turns out to be child-like and naive. Frustrated  unable to get intimate he befriends a Caucasian female  Sharon  and decides to marry her. Watch what response he gets  and the impact this has on everyone.",
    "year_of_release": 2011,
    "runtime": 108,
    "genres": "Comedy|Drama",
    "rating": 2.5,
    "cast": "Govinda|Yuvika Chaudhry|Anupam Kher|Smita Jaykar|Lucia Edwards|Sanjay Mishra|Shakti Kapoor|Harish|Ameet Chana|Gurpreet Ghuggi|Rakesh Bedi|Dimple Bagrey|Sayali Bhagat|Kuldeep Sharma|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BOGNiYTBlOGQtYTAzOS00MTg4LTkyYjEtMWZhYjhhYzA0OTEyXkEyXkFqcGdeQXVyNjE1OTQ0NjA@.jpg"
  },
  {
    "content_id": "tt1918965",
    "title": "Murder 2",
    "summary": "Orphaned at a young age  former officer with Goa Police  Arjun Vishnu Bhaagwat  now lives alone  though he has a sweetheart in gorgeous model  Priya. Amidst reports of police extending their search for missing prostitutes  specifically the Caucasian daughter of a high-profile Russian Diplomat  Arjun is hired by a gangster  Sameer  and for a fee  must not only locate missing call-girls but also their abductor. Unable to get any clues  he than enlists the assistance of Sameer to recruit a bait  Reshma  who comes from an impoverished family  for the killer. Shortly thereafter Reshma goes missing  and Arjun loses contact with her. Guilt-ridden  he decides to use all possible resources to try and locate her before she herself becomes included in the official 'missing persons' list.",
    "year_of_release": 2011,
    "runtime": 127,
    "genres": "Action|Drama|Horror",
    "rating": 6.2,
    "cast": "Emraan Hashmi|Jacqueline Fernandez|Prashant Narayanan|Sulagna Panigrahi|Sudhanshu Pandey|Sandeep Sikand|Amardeep Jha|Bikramjeet Kanwarpal|Shekhar Shukla|Raj Gopal Iyer|Surendra Rajan|Tanay Hemant Ambegowankar|Rasika Dugal|Shashi Kiran|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BODBiNTk1NjUtZDY3NC00YjUwLTgxNmItMjExZjcyNDdkZjQ1XkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt2023645",
    "title": "Phhir",
    "summary": "Manchester-based Dr. Kabir Malhotra is late at arriving at a restaurant to meet his wife  Sia. When she is not there he naturally assumes that she is annoyed with him. When he fails to hear from her  he decides to report her missing to the police. While they launch their investigation  he is approached by a woman named Disha who claims that she can assist him in locating his wife. He scoffs at her when he is told that she is a psychic  and thinks she may be looking for publicity and money. But when her prophecies and predictions turn out to be accurate as well as the police themselves claim that she assists them  he decides to enlist her help. The question remains - will her powers provide any clues as to Sia's whereabouts?",
    "year_of_release": 2011,
    "runtime": 100,
    "genres": "Drama|Mystery|Romance",
    "rating": 5.3,
    "cast": "Rajniesh Duggall|Roshni Chopra|Monica|Adah Sharma|Parag Tyagi|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BM2YxM2JhMzAtNzM2Mi00ZTlmLWFiMWEtZDAyYWJkZmE2OGU2XkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt2104937",
    "title": "Miley Naa Miley Hum",
    "summary": "Chirag comes from a wealthy background and assists his father  Siddharth Mehra  to maintain their farmland. His parents have been divorced due to incompatibility arising mainly due to his businesswoman mother  Shalini's hatred of tennis  and he lives with them every alternate month. Both would like him to get married  and accordingly Shalini picks London-based Kamiah  while Siddharth picks Bhatinda-based Manjeet Ahluwalia. Chirag  who sneaks off to practice tennis at night  is asked to make a choice but informs them that he is in love with a model named Anishka. Watch what happens when the displeased couple decide to confront and put pressure on a struggling and unknowing klutz-like Anishka to leave their son alone.",
    "year_of_release": 2011,
    "runtime": 114,
    "genres": "Comedy|Drama|Romance",
    "rating": 3,
    "cast": "Chirag Paswan|Kangana Ranaut|Neeru Bajwa|Sagarika Ghatge|Poonam Dhillon|Kabir Bedi|Kunal Kumar|Suresh Menon|Dalip Tahil|Shweta Tiwari|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMzEwZGNlYTUtZDZjYi00YTZmLTg1ZGMtOWVlNzhlMzJmOGEwXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt1734110",
    "title": "No One Killed Jessica",
    "summary": "Devastated when she is told that that her sister  Jessica Lall  is shot dead by Manish Bhardwaj  the son of Pramod  a Congress party Member of Parliament in Haryana  Delhi-based Sabrina Lall  in the company of her parents  attends the Court to view a charade that will lead to the acquittal of the accused and his accomplices seven years after the incident. Disillusioned by rampart police corruption and inefficiency; a stagnant British introduced legal system; the passing of her mother & the hospitalization of her father  she retreats unto herself. Then foul-mouthed NDTV employee  Meera Gaity  known for being more of an activist than a Journalist  takes an interest in this incident  and uncovers conspiracy & corruption that led to the accused' acquittals - leading to a near India-wide protest. While preparing herself to participate in a candle-lit procession at Delhi's India Gate  Meera will confront Sabrina and will come to know the reasons why the latter refuses to be involved in this...",
    "year_of_release": 2011,
    "runtime": 136,
    "genres": "Biography|Crime|Drama",
    "rating": 7.2,
    "cast": "Rani Mukerji|Vidya Balan|Myra Karn|Neil Bhoopalam|Rajesh Sharma|Satyadeep Misra|Yogendra Tiku|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTU4Mzk0NDY3OV5BMl5BanBnXkFtZTcwMjE1MTkyNA@@.jpg"
  },
  {
    "content_id": "tt1373156",
    "title": "Karthik Calling Karthik",
    "summary": "Born in Delhi  but now living in Mumbai  Karthik Narayan is a much abused employee with ACL Construction  secretly in love with leggy Architect  Shonali Mukherjee  who has been going steady with yet another co-worker  Ashish. After purchasing a new telephone  Karthik gets a call from a male voice that resembles his  who instructs him to follow directions; tell no one about this phone call  and he will achieve success. He follows these instructions  is soon promoted  as well as befriends Shonali  and continues following instructions from this caller. After Shonali makes it clear that she loves him  he breaks his promise  and is urged to seek counseling by her. He reluctantly does so with a Pyschiatrist  Dr. Shweta Kapadia  who hopes to cure him by visiting him early morning in his flat - not realizing the shock and trauma that await the duo.",
    "year_of_release": 2010,
    "runtime": 135,
    "genres": "Drama|Mystery|Thriller",
    "rating": 7,
    "cast": "Siddhartha Gupta|Swapnel Desai|Farhan Akhtar|Vipin Sharma|Kanchan Pagare|Ram Kapoor|Haansaa|Vivan Bhatena|Deepika Padukone|Abhay Joshi|Prachi|Zafar|Shefali Shah|Brijendra Kala|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTg4NzUyNDExMl5BMl5BanBnXkFtZTcwOTg3NTk5Mw@@.jpg"
  },
  {
    "content_id": "tt1620719",
    "title": "Dabangg",
    "summary": "Set in Uttar Pradesh  Chulbul Pandey is a young boy who lives with his mother Naini  stepfather Prajapati  and younger half-brother Makkhi. Chulbul is constantly angry that his step-father is always partial towards his own biological son. 21 years later  Chulbul is a rowdy  mischievous  and sometimes crooked cop  who refers to himself as \"Robin Hood.\" He does everything in his own indomitable way; whether it's dealing with corrupt politician and thug Chedi Singh  fighting a family feud  or romancing stunning Rajo.",
    "year_of_release": 2010,
    "runtime": 126,
    "genres": "Action|Comedy",
    "rating": 6.3,
    "cast": "Salman Khan|Sonakshi Sinha|Sonu Sood|Arbaaz Khan|Vinod Khanna|Dimple Kapadia|Om Puri|Anupam Kher|Mahie Gill|Tinnu Anand|Mahesh Manjrekar|Murli Sharma|Malaika Arora|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTMyOTQ0Mzg2Ml5BMl5BanBnXkFtZTcwMDcyOTM5NA@@.jpg"
  },
  {
    "content_id": "tt1773015",
    "title": "Phas Gaye Re Obama",
    "summary": "Phase Gaye Re Obama is a comedy set against the backdrop of global recession/meltdown that originated in USA. The film traces the journey of OM Shashtri  an American citizen of Indian origin  who loses all his wealth overnight to the global recession & has been asked to vacate his home by the bank unless he pays up $100 000 (mortgaged amount) within 30 days. Seeing no other option Om comes to India to sell a small piece of an ancestral property. But within days of landing in India he is kidnapped by a 'recession-hit' underworld gang those who think that he is still a millionaire. What happens to Om  is he able to save his home  how did the 'poor' gangster cope with their 'poor' catch & what do small town Indian gangsters have to say to President Obama...is largely forms the rest of the story. The film  showcases how global recession/ meltdown impacted lives from an America based businessman to underworld dons in the dusty plains of small town India.",
    "year_of_release": 2010,
    "runtime": 108,
    "genres": "Comedy|Crime|Drama",
    "rating": 7.5,
    "cast": "Rajat Kapoor|Neha Dhupia|Sanjay Mishra|Manu Rishi Chadha|Pragati Pandey|Brijendra Kala|Sushil Pandey|Sumit Nijhawan|Amit Sial|Surendra Rajan|Devender Chaudhary|Amole Gupte|Roopesh Thapliyal|Dinesh Nag|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BM2U1NTllNzEtOTJhOS00MWE2LThmZWQtNjIxYmJiMmRkNmNiXkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt1781838",
    "title": "Isi Life Mein",
    "summary": "After emerging as a topper - not only in Sophia Senior Secondary School but also in Rajasthan - Ajmer-born Rajnandini Khandelwal  who lives a conservative lifestyle with a joint family - including her parents  grandmother  as well as an uncle and aunt - is now taught basic cooking  while her father  Ravimohan  a devout Hindu  looks for a suitable groom. Her mother wants her to learn western cuisine and arranges for her stay in Mumbai with her sister  Shubha  and brother-in-law  Mukul. Once there  she is allowed to attend Modern College where she enlists in The Dramatics Society  and is selected to play the role of 'Kate' in Shakeshpeare's 'Taming of the Shrew'. She adapts to her new lifestyle  gains confidence  has her hair styled  wears revealing western dresses  and is attracted to fellow-collegian Vivaan. Then a surprise visit from Ravimohan will turn the tables against her. She will not only be admonished  but also be taken back to Ajmer where her marriage has been arranged with ...",
    "year_of_release": 2010,
    "runtime": 139,
    "genres": "Comedy|Drama|Romance",
    "rating": 5.7,
    "cast": "Akshay Oberoi|Sandeepa Dhar|Mohnish Bahl|Prachee Shah Paandya|Shagufta Ali|Poonam Gulati|Madhur Arora|Rishi Krishnan|Aditya Raj|Dilip Roy|Gajendra Chouhan|Rajesh Dubey|Vibha Anand|Rajesh Balwani|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNmEzZmY5ZGEtYTkwNy00YjYwLTk3OTktZTYyYzlkNTgxMmM1XkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  }
]