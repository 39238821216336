export const watchHistoryData = [
  {
    "content_id": "tt8291224",
    "title": "Uri: The Surgical Strike",
    "summary": "Divided over five chapters  the film chronicles the events of the surgical strike conducted by the Indian military against suspected militants in Pakistan occupied Kashmir. It tells the story of the 11 tumultuous events over which the operation was carried out. Indian army special forces carry out a covert operation to avenge the killing of fellow army men at their base by a terrorist group.",
    "year_of_release": 2019,
    "runtime": 138,
    "genres": "Action|Drama|War",
    "rating": 8.4,
    "cast": "Vicky Kaushal|Paresh Rawal|Mohit Raina|Yami Gautam|Kirti Kulhari|Rajit Kapoor|Ivan Rodrigues|Manasi Parekh|Swaroop Sampat|Riva Arora|Yogesh Soman|Fareed Ahmed|Akashdeep Arora|Kallol Banerjee|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMWU4ZjNlNTQtOGE2MS00NDI0LWFlYjMtMmY3ZWVkMjJkNGRmXkEyXkFqcGdeQXVyNjE1OTQ0NjA@.jpg"
  },
  {
    "content_id": "tt6836936",
    "title": "Saaho",
    "summary": "This action thriller is about a power battle which is taking place in the higher echelons of power unrelated and unconnected episodes occurring in different parts of the globe  Intertwine in an unforeseen manner to a revelation of mind games. The story delves the audience into the game of a brilliant master mind to reveal the true nature of all that are involved. It challenges the perceptions of who is the hunter and who is the hunted.",
    "year_of_release": 2019,
    "runtime": 170,
    "genres": "Action|Thriller",
    "rating": 5.5,
    "cast": "Jacqueline Fernandez|Shraddha Kapoor|Prabhas|Jackie Shroff|Mandira Bedi|Neil Nitin Mukesh|Chunky Pandey|Arun Vijay|Mahesh Manjrekar|Evelyn Sharma|Tinnu Anand|Michael Segal|Philip Fornah|Vennela Kishore|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNDExZjFiYjItMmM3OS00OGUxLTliMTctMzFhZDkzMmFhMzY1XkEyXkFqcGdeQXVyMTA1NTAyMjAw.jpg"
  },
  {
    "content_id": "tt9378950",
    "title": "Ghost (2019 film)",
    "summary": "The story of the film follows Karan Khanna  a politician of Indian origin in the United Kingdom  who is accused of killing his wife. He tells his lawyer that a spirit committed the murder and should be tried.",
    "year_of_release": 2019,
    "runtime": 130,
    "genres": "Horror|Thriller",
    "rating": 8.7,
    "cast": "Sanaya Irani|Shivam Bhaargava|Vikram Bhatt|Ameet Chana|Ananya Sengupta|Caroline Wilde|Monisha Hassen|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNDNhMGUwMzMtYTNkMi00ZjUxLWIwYzQtNzA2MjkzNmJkNzQxXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3224288",
    "title": "Beyond the Clouds (2017 film)",
    "summary": "Teenager Amir is constantly dodging trouble while dealing drugs in the underbelly of Mumbai. Following a drug bust  he evades the cops and ends up on the doorstep of his estranged sister Tara. Complications from concealing Amir land Tara in jail  but she still sees her brother as her only hope of living in the outside world again. While their lives have been darkened by despair  hope may shine from beyond the clouds.",
    "year_of_release": 2017,
    "runtime": 120,
    "genres": "Drama|Family",
    "rating": 6.9,
    "cast": "Ishaan Khattar|Malavika Mohanan|Goutam Ghose|Sharada|Tannishtha Chatterjee|Shashank Shende|Hema Singh|Akash Gopal|Dhwani Rajesh|Amruta Santosh Thakur|Shivam Pujari|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZjk2OWE1OWItOGFmMy00YzcyLWE3MjMtOTJiMjNhM2QwNWI0XkEyXkFqcGdeQXVyMTk4NzY0NDI@.jpg"
  },
  {
    "content_id": "tt7919680",
    "title": "Karwaan",
    "summary": "The movie depicts a journey carried out from Bangalore to Kochi by two friends  the reserved Avinash played by Dulquer Salmaan  and his friend Shaukat played by Irrfan Khan with a lighter take on life  who pick up the chirpy teenager Mithila on the way.",
    "year_of_release": 2018,
    "runtime": 114,
    "genres": "Comedy|Drama",
    "rating": 7.5,
    "cast": "Irrfan Khan|Dulquer Salmaan|Mithila Palkar|Amala Akkineni|Akash Khurana|Kriti Kharbanda|Donna Munshi|Abir Abrar|Beena Banerjee|Siddharth Menon|Adrika Shetty|Sameer Saxena|Kallirroi Tziafeta|Richard John Lovatt|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYjYxZjc1YmMtNTIyOS00OGUyLWI5MTYtOWZiNzc5Yzc4MDBhXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt5756052",
    "title": "Jeena Isi Ka Naam Hai (film)",
    "summary": "The film presents the naÃ¯ve vulnerability of human life  the sincere saga of love and pain  and the glimpse of human emotions in raw form. So much of human nature is captured within the frames of the film  yet it takes you to the various land giving a realistic view of existence - the story traversing from a small town of Rajasthan  moving to the city of dreams - Mumbai and then goes on to the city that never sleeps - New York  ride us through interesting characters  each one with a diverse and unique character adding slice of life.",
    "year_of_release": 2017,
    "runtime": 173,
    "genres": "Drama",
    "rating": 5.6,
    "cast": "Arbaaz Khan|Himansh Kohli|Ashutosh Rana|Manjari Fadnnis|Prem Chopra|Rati Agnihotri|Supriya Pathak|Aayam Mehta|Hannah Panneriy|Roma Bali|Ashok Banthia|Raju Kher|Poonam Labana|Bhanu Pratap Singh|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYzg5MDNhYjEtNjRjMS00YTFmLWI1YzEtMWY1YTIzNWU2ZTVjL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt5617172",
    "title": "Qaidi Band",
    "summary": "A riveting tale of undertrials who are brought together to form a band in jail for a social event. As their popularity grows through social media  they use their music to protest against jail authorities & the Indian judicial system. Eventually  when all hope fades  music becomes their only hope but will it set them free?",
    "year_of_release": 2017,
    "runtime": 119,
    "genres": "Drama|Music",
    "rating": 5.7,
    "cast": "Aadar Jain|Anya Singh|Mikhail Yawalkar|Anna Ador|Cyndy Khojol|Sange|Peter Muxka Manuel|Prince Parvinder Singh|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTY4OTc2OTYzOF5BMl5BanBnXkFtZTgwMzU3NTYyNTM@.jpg"
  },
  {
    "content_id": "tt7154994",
    "title": "Ranchi Diaries",
    "summary": "Ranchi Diaries depicts the story of Gudiya and her friends who hail from a small town Ranchi. She has the dream of becoming a pop sensation like Shakira. Her talent draws unwanted attention of the local Mafia kingpin Thakur bhaiya and makes her elope with childhood lover Manish. The chain of events cross each other and create a ruckus. To get out of this trouble Gudiya and her friends plan for a bank heist. Their ambitious bank heist becomes the joke of the town when they attempt it. Amidst the chaos between the Goons and the cops whether Gudiya and her friends could make it to the survival  is what the #RanchiDiaries all about.",
    "year_of_release": 2017,
    "runtime": 93,
    "genres": "Thriller",
    "rating": 7.4,
    "cast": "Soundarya Sharma|Pradeep Singh|Anupam Kher|Jimmy Sheirgill|Satish Kaushik|Himansh Kohli|Taaha Shah Badusha|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMjZmMTA5NDEtODlhYy00NWUwLWEyNzctNGZkYWFkMDc0MGJiXkEyXkFqcGdeQXVyNzg4ODQ2MTk@.jpg"
  },
  {
    "content_id": "tt6712014",
    "title": "Fukrey Returns",
    "summary": "After sending Bholi Punjaban in jail Fukrey Boys Hunny Choocha Zafar and Lali are doing well their success in life still depends on Choocha's dreams.A frustrated Bholi is in jail since a year and cant arrange for her bail as her criminal activities have stopped and there is no source of income.Bholi contacts minister Babulal Bhatia to bail her out in returns Babulal tells her that she should pay him 10 Crore within 10 days of her release or else on 11th day she will be back in jail.Bholi tracks Hunny Choocha Zafar and Lali and tells them as they were responsible for her arrest they should arrange the bail amount by opening a fraud finance company which will benefit them as well as the people.But Babulal plays a part and puts the company into losses.Fukrey Boys now are on the run to save their lives from Bholi as well as the people who have invested in the company.",
    "year_of_release": 2017,
    "runtime": 141,
    "genres": "Comedy",
    "rating": 6.5,
    "cast": "Pulkit Samrat|Manjot Singh|Ali Fazal|Varun Sharma|Priya Anand|Vishakha Singh|Pankaj Tripathi|Richa Chadha|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYmUyYzI5MjEtZGU5Mi00YTFiLWJmYjMtOWFmMzlmNTdmZTdjXkEyXkFqcGdeQXVyNzU3OTY4NTc@.jpg"
  },
  {
    "content_id": "tt5755606",
    "title": "Banjo (2016 film)",
    "summary": "Mickey a sound Recordist records a tune during Mumbai Ganpati festival and sends it to her friend Chritina in the US  Christina comes to Mumbai in search of the band.Christina along with Mickey audition many band but they don't find the band which originally played the tune.Taraat who extorts money for local politician had played the tune with his band for the festive season he is given a job of showing Chritina the whole Mumbai but doesn't disclose him self of being a Banjo player as he feels that as a Banjo player he and his band haven received the required respect.",
    "year_of_release": 2016,
    "runtime": 137,
    "genres": "Action|Drama|Music",
    "rating": 5,
    "cast": "Riteish Deshmukh|Nargis Fakhri|Aditya Kumar|Ram Menon|Dharmesh Yelande|Uday Sabnis|Janardhan Parab|Luke Kenny|Sheeba George|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNzk4NTI1ZDYtYmU3MC00ZTk2LWI3OGQtZGE5OWY1ZmM2ZjE0XkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt5928576",
    "title": "Wah Taj",
    "summary": "Tukaram Marathe a farmer from Maharashtra comes to Agra with his family and claims that the land where Taj Mahal stands is his.",
    "year_of_release": 2016,
    "runtime": 125,
    "genres": "Drama",
    "rating": 6.1,
    "cast": "Shreyas Talpade|Manjari Fadnnis|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMGY3NjBjNDMtMzQzZS00ZTlhLWFlMmYtZmQ5Yjk5MzAwMTAwXkEyXkFqcGdeQXVyMjQwOTU5NTg@.jpg"
  },
  {
    "content_id": "tt4940456",
    "title": "Mirzya (film)",
    "summary": "A horse groom reconnects with his childhood love  but she is engaged to marry the local prince.",
    "year_of_release": 2016,
    "runtime": 129,
    "genres": "Action|Adventure|Drama",
    "rating": 4.6,
    "cast": "Harshvardhan Kapoor|Saiyami Kher|Art Malik|Anuj Choudhry|Om Puri|K.K. Raina|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTJhZThiZmYtYzJmYS00NWI5LThkY2MtZmU2NjVkZDFhMDU4XkEyXkFqcGdeQXVyNjA3NTkyNTI@.jpg"
  },
  {
    "content_id": "tt2424988",
    "title": "Gabbar Is Back",
    "summary": "Gabbar Singh Rajput (Akshay Kumar) creates his own vigilante military network called the Anti-Corruption Force (ACF) that eliminates the most corrupt individuals  from all walks of life  in a systematic manner. As pressure builds on Police and Government to track down the man who masterminds these killings  Balbir Singh (Sonu Sood) is specially summoned to head the task. He is aided by Suryam (Prakash Raj) who  though being just a constable in the mammoth Police force  is compelled by his instincts to see through the underlying design.",
    "year_of_release": 2015,
    "runtime": 128,
    "genres": "Action",
    "rating": 7.1,
    "cast": "Akshay Kumar|Shruti Haasan|Kareena Kapoor|Jaideep Ahlawat|Chitrangda Singh|Sunil Grover|Shruti Bapna|Jitendra Trehan|Suman|Vikas Shrivastav|Ishita Vyas|Rajiv Kachroo|Sanjay Gurbaxani|Jagdish Rajpurohit|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMjFhNDQ5NjEtNDE1ZS00YjYwLTgzMjItN2Y2M2RlMjNhZGI4XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3886444",
    "title": "Khamoshiyan",
    "summary": "An erotic love triangle revolving around a writer adrift in the icy slopes of Kashmir where he discovers a woman with a strange  silent past.",
    "year_of_release": 2015,
    "runtime": 122,
    "genres": "Drama|Horror|Romance",
    "rating": 4,
    "cast": "Gurmeet Choudhary|Ali Fazal|Sapna Pabbi|Debina Choudhary|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZjg0MDM4OGMtNTYyMi00N2ZkLWFjOWYtMzNiZmIyMzMzNDI1XkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt2872050",
    "title": "Jai Jawaan Jai Kisaan (film)",
    "summary": "Jai Jawaan Jai Kisaan is a journey about Lal Bahadur Shashtri's glorious life. The movie begins with the various freedom fights in the country during the pre-independence era. A time where two groups ( Garam Dal and Naram Dal) had waged a war against Britishers with different set of ideologies. It was under the leadership of Gandhi ji that Naram Dal took on the Britishers and led a peaceful march towards independence. Lal Bahadur Shastri was a prominent personality in this group and has a huge role to play in the history of India's independence. However this dynamic leader had his own story to deal with during the initial phase of his life. A low economic background  untimely death of his father and a mother to look after...these were just some of the problems that gave Lal Bahadur Shashtri a direction in life. After completing his studies in Banaras  Shashtri ji came to Allahabad and that's when he witnessed a revolution in the country. Inspired to take part in the freedom fight  Lal...",
    "year_of_release": 2015,
    "runtime": 100,
    "genres": "Biography|Drama|History",
    "rating": 5.4,
    "cast": "Rati Agnihotri|Kanchan Awasthi|Rishi Bhutani|Prem Chopra|Imran Hasnee|Jatin Khurana|Avinash Kumar|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMjk4Nzg4MjEyMV5BMl5BanBnXkFtZTgwNzAzMzAxNDE@.jpg"
  },
  {
    "content_id": "tt4107858",
    "title": "MSG: The Messenger",
    "summary": "Guru Ji is a spiritual leader who has a huge follower base. He has accepted the challenge of eradicating social evils including drugs and gender-related issues that have been prevailing in the society. Those with vested interest and apathetic towards the welfare of the society are now disturbed as there's someone who has taken control of the situation. They conspire to kill him.",
    "year_of_release": 2015,
    "runtime": 197,
    "genres": "Action|Comedy|Drama",
    "rating": 6,
    "cast": "Gurmeet Ram Rahim Singh|Daniel Kaleb|Flora Saini|Jayshree Soni|Olexandra Semen|Gaurav Gera|Raju Pandit|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMDM3MmU2MjItYmNmNC00NGY2LTkzMjQtOWU1YTQ4NDcxOTQ4XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt4541102",
    "title": "Uvaa",
    "summary": "Ram  Anil  Vikram  Salmaan and Deenbandhu are the rebellious brats of suburban Delhi NCR  with the world at their feet and nothing to worry they stomp everything in their path without knowing the consequences  but challenge lay ahead when all of them are put in a totally new world of convent school  here they survive and thrive only to find love. But one night they encounter a horrifying event which puts them in the hands of law. Their lives change forever  they have to fight for justice and moral integrity to make an example for the world.",
    "year_of_release": 2015,
    "runtime": 138,
    "genres": "Comedy|Drama",
    "rating": 4.9,
    "cast": "Mohit Baghel|Sheena Bajaj|Manish Chaudhary|Lavin Gothi|Vinti Idnani|Jyoti Kalash|Rajit Kapoor|Yukti Kapoor|Elena Kazan|Hussain Khan|Neha Khan|Neha Khan|Bhupendra Singh Megh|Rohan Mehra|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTk1ZWQ5NjEtYTY0ZS00NzBlLTg2OTktZjkwZTVkNmIxMzU2XkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt4610308",
    "title": "Ishqedarriyaan",
    "summary": "Ishqedarriyaan is a story about love  sacrifice  family values and relationships. Rishteydarriyaan means relationships and Ishqedarriyaan signifies the relationship when you fall in love! The movie stars Mahaakshay Chakraborty  Evelyn Sharma and Mohit Dutta. Evelyn Sharma is the lead actress  who will play the character of Luvleen  a teacher by profession who wants to collect donations for her grandfather's school. Mahaakshay Chakraborty plays Aagam Diwan  a millionaire who loves his profession more than anything else. Mohit Dutta will play Arjun  a passionate singer.",
    "year_of_release": 2015,
    "runtime": 132,
    "genres": "Romance",
    "rating": 4.9,
    "cast": "Mimoh Chakraborty|Kavin Dave|Mohit Dutta|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTY4MTcyNTk0MF5BMl5BanBnXkFtZTgwNzgyODI0NTE@.jpg"
  },
  {
    "content_id": "tt2708644",
    "title": "I Love NY (2015 film)",
    "summary": "This is a one-night story - it's New Year eve and two strangers living in two different cities [New York/Chicago] are tossed into each other's company by the hand of fate in a comedy of errors that's as funny as it is enchanting. And just as the world is celebrating New Years  the two stranger's realize their own romances are falling apart... Making a desperate bid to sustain their own relationships they inadvertently fall in love over one magical New Year's eve...",
    "year_of_release": 2015,
    "runtime": 127,
    "genres": "Comedy|Romance",
    "rating": 3.6,
    "cast": "Sunny Deol|Kangana Ranaut|Prem Chopra|Kubbra Sait|Maya Alagh|Manoj Joshi|Navin Chowdhry|Tannishtha Chatterjee|Jiten Mukhi|Virag Mishra|Lillete Dubey|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZGNiOWRiZWItNDA3ZS00Njk3LWJlODMtOWY1MTMyZmRhY2QxL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt4430212",
    "title": "Drishyam (2015 film)",
    "summary": "Vijay Salgaonkar runs a cable TV network in a remote and hilly village of Goa. He lives a happy life with his wife Nandini and two daughters. A 4th grade dropout & an orphan  Vijay has worked his way towards success with his sheer hard-work & street smartness in order to provide for his family who mean the world to him. A man of few means & needs; Vijay believes life is to be lived simplistically - his wife  while she loves him devoutly  wants Vijay's thrifty & miserly behavior to end and wants the world for her family. If there's one thing that Vijay cannot live without  it is his passion for watching films. Such is his 'filmy' obsession that he doesn't mind staying up all night at his office binging on TV movies! In a bizarre turn of events; a teenage boy goes missing; he is the son of a headstrong & no-nonsense cop; IG Meera Deshmukh and the Salgaonkar family is the prime suspect! Will a humble & middle class man  be able to protect his family from oppression of the powerful? How ...",
    "year_of_release": 2015,
    "runtime": 163,
    "genres": "Crime|Drama|Mystery",
    "rating": 8.2,
    "cast": "Ajay Devgn|Shriya Saran|Tabu|Rajat Kapoor|Ishita Dutta|Mrunal Jadhav|Prasanna Ketkar|Yogesh Sonam|Prathamesh Parab|Rishab Chadha|Ashish Warang|Anil Nagarkar|Tarun Shukla|Smita Inamdar|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYmJhZmJlYTItZmZlNy00MGY0LTg0ZGMtNWFkYWU5NTA1YTNhXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt2675978",
    "title": "Dedh Ishqiya",
    "summary": "Dedh Ishqiya is a 2014 Indian comedy thriller film. Out from jail  Khalujaan a.k.a Iftekhar (Naseeruddin Shah) and Babban (Arshad Warsi) have stolen a necklace made of royal jewels and are on the run. They part ways and meet at the Majidabad palace  where Iftekhar is competing with Urdu poets to win the heart of the nawab's widow Begum Para (Madhuri Dixit). Her quick-witted and mysterious friend and assistant Munniya (Huma Qureshi) slowly warms up to Babban and they together plot a kidnapping. Khalujaan (Naseeruddin Shah)and Babban (Arshad Warsi)  the two romantic thieves are back in Dedh Ishqiya  sequel to the acclaimed and successful Ishqiya with their romantic adventures. And this time love will take them through the SEVEN STAGES OF LOVE... with the beautiful and dangerous Madhuri Dixit as Begum Para and Huma Qureshi as Munniya.",
    "year_of_release": 2014,
    "runtime": 152,
    "genres": "Comedy|Drama|Romance",
    "rating": 7.1,
    "cast": "Madhuri Dixit|Naseeruddin Shah|Arshad Warsi|Huma Qureshi|Vijay Raaz|Manoj Pahwa|Salman Shahid|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTkxMzU3MDMxMV5BMl5BanBnXkFtZTgwMzM5MDk3MDE@.jpg"
  },
  {
    "content_id": "tt2762334",
    "title": "Main Tera Hero",
    "summary": "Sreenath Prasad aka Seenu is the most notorious boy in Ooty. He drops out of college in Ooty in order to pursue education in a Bangalore college. Here he falls in love with Sunaina. But Sunaina is forced to marry Angad  a local police officer  who is more of a terrorizing gangster. Seenu also has another die-hard lover in the form of Ayesha  of whom he is unaware. He had once saved her friends and Ayesha instantly knew that he was the perfect man for her. Ayesha's father is a big gangster and kidnaps Sunaina to get hold of Seenu and compel him to marry his daughter.",
    "year_of_release": 2014,
    "runtime": 128,
    "genres": "Action|Comedy|Romance",
    "rating": 5.2,
    "cast": "Varun Dhawan|Ileana D'Cruz|Nargis Fakhri|Arunoday Singh|Anupam Kher|Saurabh Shukla|Daljeet Singh Gujral|Rajpal Yadav|Evelyn Sharma|Salman Khan|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZjBiYWU0ZmMtZDE0NC00ZTUxLWE3OTQtY2YyNjVjMTRlOGJjXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt3501994",
    "title": "Hawaa Hawaai",
    "summary": "Arjun dreams of skating. Can Arjun follow his dream and be a champion?",
    "year_of_release": 2014,
    "runtime": 120,
    "genres": "Drama|Family|Sport",
    "rating": 7.4,
    "cast": "Makrand Deshpande|Partho A. Gupte|Devraya Gole|Neha Joshi|Rekha Kamat|Sanjay Dadich|Sanjay Tripathi|Hardik Raheja|Saqib Saleem|Srishti Sharma|Anuj Sachdeva|Manav Hirey|Zufin|Shardha Shripad Nabar|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BODM4MTE0ODIwNF5BMl5BanBnXkFtZTgwODQxMTI1MTE@.jpg"
  },
  {
    "content_id": "tt2283894",
    "title": "Riyasat (film)",
    "summary": "Riyasat is a story about the much loved Sahab played by the late Rajesh Khanna. Sahab has ruled for many years and has established his kingdom. People look up to him for everything. He has truly been the Godfather for the people of city. But then  few people enter the city with malicious intent which Sahab is aware of. Obviously  Sahab protects his kingdom from evil eyes and thus starts a war which changes Saheb's life forever. Will Sahab protect his kingdom? Will Sahab win over the evils and emerge as a winner?",
    "year_of_release": 2014,
    "runtime": 80,
    "genres": "Crime",
    "rating": 6.5,
    "cast": "Rajesh Khanna|Aryeman Ramsay|Raza Murad|Aryan Vaid|Vishwajeet Pradhan|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMDVjMmY5Y2EtZmQ3Ny00NTEwLTk3N2YtYzVmZDBmMmM0NTc3XkEyXkFqcGdeQXVyMjU4NDY1ODA@.jpg"
  },
  {
    "content_id": "tt2461132",
    "title": "Happy New Year (2014 film)",
    "summary": "A team of losers attempt to pull off mass revenge against a past traitor. They are required to win a dance competition as part of the plan  they get entangled with the presence of a somewhat unintelligent local dance performer who cannot learn the backstory of the betrayal.",
    "year_of_release": 2014,
    "runtime": 180,
    "genres": "Action|Comedy|Music",
    "rating": 5.1,
    "cast": "Shah Rukh Khan|Deepika Padukone|Anupam Kher|Abhishek Bachchan|Anurag Kashyap|Jackie Shroff|Boman Irani|Sajid Khan|Prabhu Deva|Gauri Khan|Sonu Sood|Malaika Arora|Varun Pruthi|Kiku Sharda|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMjEwZGQwZWMtNTU4Zi00YWVlLTlhZTYtZjc2MzJmMGVmMDQwXkEyXkFqcGdeQXVyNjQ3ODkxMjE@.jpg"
  },
  {
    "content_id": "tt1704680",
    "title": "A Decent Arrangement",
    "summary": "A Decent Arrangement is the story of Ashok Khosla  an Indian-American copywriter  who travels to India for an arranged marriage. After he encounters an American woman traveling through India and is set up with an Indian woman who unexpectedly captivates him  Ashok must navigate the complexity of cultural traditions and the leanings of his own heart. With subtle comedy and true-to-life drama  A Decent Arrangement shows us a side of India not commonly seen by western audiences and delivers an emotional story that resonates with those of us in search of our place in the world.",
    "year_of_release": 2011,
    "runtime": 96,
    "genres": "Comedy|Drama",
    "rating": 5.7,
    "cast": "Shabana Azmi|Diksha Basu|Adhir Bhat|Adam Laupus|Lethia Nall|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZjk0NGQwZjktNWVlNy00MmJkLTkzYzMtZmQ1NDI3YTEyOGFmXkEyXkFqcGdeQXVyMzM2MzQzNTQ@.jpg"
  },
  {
    "content_id": "tt3380264",
    "title": "Kill Dil",
    "summary": "Once upon a time in North India  two killers - Dev and Tutu  roamed free. Abandoned when young and vulnerable  Bhaiyaji gave them shelter and - nurtured them to kill. All is normal in their lives until destiny throws free-spirited Disha into the mix. What follows is a game of defiance  deception and love. Played by Ranveer Singh (Dev)  Ali Zafar (Tutu)  Parineeti Chopra (Disha) and Govinda (Bhaiyaji)  Kill Dil is an entertaining thriller about guns and romance.",
    "year_of_release": 2014,
    "runtime": 127,
    "genres": "Action|Drama|Romance",
    "rating": 4.3,
    "cast": "Ranveer Singh|Ali Zafar|Parineeti Chopra|Govinda|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZWI0YmI2NmQtMzdjZS00Yzk1LTljZWQtZGE0Y2I5YmZjYWY2XkEyXkFqcGdeQXVyNTkzNDQ4ODc@.jpg"
  },
  {
    "content_id": "tt1773764",
    "title": "Ship of Theseus (film)",
    "summary": "If the parts of a ship are replaced  bit-by-bit  is it still the same ship? A celebrated experimental photographer struggles with the loss of her intuitive genius as an unexpected aftermath of a physical change; an intellectual monk confronting a complex ethical dilemma with a long held ideology  has to choose between principle and death; and a young stockbroker  following the trail of a stolen kidney  learns how intricate morality could be. These disparate characters manifest philosophical dilemmas in their personal lives  but their narratives converge to reveal an even larger fabric of connections  meaning  beauty  existence and death in a delicately poetic finale.",
    "year_of_release": 2012,
    "runtime": 140,
    "genres": "Drama",
    "rating": 8,
    "cast": "Aida Elkashef|Yogesh Shah|Faraz Khan|Hannan Youssef|Narendra Shah|Chitra Shah|Manan Shah|Aishwarya|Balaji Janardhan|Abbas K.|Bharat Pardesi|Pradeep Sinha|Akhtar Bhale|Sidharth Meer|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTU5Njc1NjkxMl5BMl5BanBnXkFtZTgwNTkzNTQwMDE@.jpg"
  },
  {
    "content_id": "tt2246595",
    "title": "Blood Money (2012 film)",
    "summary": "The film is about a young and simple man  Kunal played by Kunal Khemu who is in love with Arzoo played by Amrita Puri  however her parents do not allow her to marry him  as he is unsuccessful. Runing away to get married. Kunal shifts to Cape Town and starts to work in a small business of diamond-exporting. Kunal slowly is dragged into the under hand dealing of the diamond trading company.",
    "year_of_release": 2012,
    "runtime": 109,
    "genres": "Action|Drama|Thriller",
    "rating": 5.1,
    "cast": "Manish Chaudhary|Kunal Khemu|Amrita Puri|Shekar Shukla|Teeshay|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMGNmYTZjN2YtZDA3Mi00YjNhLTgxZDUtMmJlYzc0ZDRkZjFjXkEyXkFqcGdeQXVyMTcyNDY1NDgw.jpg"
  },
  {
    "content_id": "tt3431756",
    "title": "Rakhtbeej",
    "summary": "Rakhtbeej is the story of two men  from two different generations. Even though their worlds are different their lives keep intersecting. Abhay Jatav from humble beginnings  ends up becoming the most dreaded dacoit in UP. Ajay also from humble beginings  becomes the CEO of Dabral Group of Industries  at a very young age. Abhay knows Sarita from a long time  and Sarita is in love with Abhay. But when a simple village girl Poonam enters Ahbay's life he totally forgets about Sarita. Sanjana  Dabral's daughter is in love with Ajay from a long time. But when supermodel Priya enters his life  he is blinded by her love. Abhay goes out of his way to help Maya Singh win the village elections. But Maya Singh later backstabs Abhay. Abhay loose his friends and those dear to him and this changes his fate forever. Ajay trusts Dharam  his competitor in business and love  but Dharam backstabs him. Abhay becomes a victim of Politics  Police and Dacoits  while Ajay is the victim of business rivalry. ...",
    "year_of_release": 2012,
    "runtime": 120,
    "genres": "Thriller",
    "rating": 3.1,
    "cast": "Julia Datt|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMWNkMmYyZTctNjliMC00YWJjLWI2OTAtMGM0ZjVjMWIyZjE5XkEyXkFqcGdeQXVyNDgzNDc2OTk@.jpg"
  },
  {
    "content_id": "tt2187972",
    "title": "Shirin Farhad Ki Toh Nikal Padi",
    "summary": "Farhad Pastakia (Boman Irani) has a dream job as a bra-and-panty salesman. Except that he is a 45-year old Parsi bachelor still living with his overbearing mother and grandmother. But it's not like he's stopped trying. Desperate to get him married  Farhad's mother drags him to see women and even to embarrassing Parsi matrimony services. In the midst of all the daily humdrum  Farhad meets the woman of his dreams: Shirin Fugawala ('Farah Khan')  who drops by his store. Shirin  who works at the Parsi Trust  hits it off with Farhad from the start. Everything seems to be perfect and Farhad gets ready to introduce Shirin to his mother. But mummy becomes the villain in their story when she discovers that Shirin is the devious Parsi Trust Secretary who got the illegal water tank in their home demolished. The water tank being the 'aakhri nishaani' of Farhad's late father doesn't help matters. The ups and downs in Shirin-Farhad's relationship and how the two try to make it work is the rest of ...",
    "year_of_release": 2012,
    "runtime": 111,
    "genres": "Romance",
    "rating": 4.2,
    "cast": "Freny Bhagat|Dinyar Contractor|Nauheed Cyrusi|Kavin Dave|Kurush Deboo|Boman Irani|Daisy Irani|Farah Khan|Mahabanoo Mody-Kotwal|Tanay Sarda|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BYjAwNDAxYTUtODliNC00Y2JmLWExNDAtZDgyNzc3MjRiMDFkXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt1787988",
    "title": "Talaash: The Answer Lies Within",
    "summary": "Seeing reflections of Mumbai under the red light  Talaash is a tale of love lost  fatal attraction and above all the quest to solve a perfect crime. Suspense at its core  Talaash explores Mumbai's underbelly like never before. Aamir Khan plays an investigation officer  Inspector Shekhawat who receives a phone call early in the morning informing him about death and an accident and how everything starts to unfold from there. The case turns into a life altering chase for Inspector Shekhawat when he is forced to reel under the repercussions of a broken married life with his wife Roshni played by Rani Mukherji and come face to face with his suppressed grief. Being on his investigational quest and fighting it out with personal struggle  Inspector Shekhawat meets a sex worker Rosie played by Kareena Kapoor who further adds shades of mystery to the puzzle. What looks like a simple car accident investigation turns into a haunting mystery as further investigations show many anomalies stringed ...",
    "year_of_release": 2012,
    "runtime": 140,
    "genres": "Crime|Drama|Mystery",
    "rating": 7.2,
    "cast": "Aamir Khan|Kareena Kapoor|Rani Mukerji|Nawazuddin Siddiqui|Rajkummar Rao|Rajshri Deshpande|Vivan Bhatena|Subrat Dutta|Aditi Vasudev|Sheeba Chaddha|Vikas Shrivastav|Shernaz Patel|Ashish Nayyar|Ashraf Ul Haq|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNTg5NzI3NzQzNl5BMl5BanBnXkFtZTcwMjM3MDU2OA@@.jpg"
  },
  {
    "content_id": "tt1784589",
    "title": "Players (2012 film)",
    "summary": "The Indian remake of the Italian Job. Romanian gold is to be transferred from Russia to Romania after many years. A gang made up of a con man  a con women  an illusionist  a makeup artist  an explosive cum gadgets expert and world's number 1 hacker steal the gold. But the hacker named spider betrays the whole team and takes all the gold.",
    "year_of_release": 2012,
    "runtime": 163,
    "genres": "Action|Crime|Thriller",
    "rating": 4.1,
    "cast": "Vinod Khanna|Abhishek Bachchan|Bobby Deol|Sonam Kapoor|Bipasha Basu|Neil Nitin Mukesh|Sikander Kher|Omi Vaidya|Johnny Lever|Shweta Bhardwaj|Mustafa Burmawala|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BOTQyNmQyYmYtMTU0NS00MGRiLTlhYTEtYmZiZDNkYTY4ZDQ4XkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt1562872",
    "title": "Zindagi Na Milegi Dobara",
    "summary": "Three friends decide to turn their fantasy vacation into reality after one of their friends gets engaged.",
    "year_of_release": 2011,
    "runtime": 155,
    "genres": "Comedy|Drama",
    "rating": 8.1,
    "cast": "Hrithik Roshan|Farhan Akhtar|Abhay Deol|Katrina Kaif|Kalki Koechlin|Ariadna Cabrol|Naseeruddin Shah|Deepti Naval|Surpreet Bedi|Anisa Butt|Juan Jose Amador|Aashrita Kamath|Lolo Herrero|Barbara Holzer|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZGFmMjM5OWMtZTRiNC00ODhlLThlYTItYTcyZDMyYmMyYjFjXkEyXkFqcGdeQXVyNDUzOTQ5MjY@.jpg"
  },
  {
    "content_id": "tt1627924",
    "title": "Mausam (2011 film)",
    "summary": "Circa 1992 Mallukot-based Harinder falls head-over-heels in love with Aayat  who has come to live there with her Bua  Fatimah  from Kashmir. Hoping to woo and marry her  despite of receiving romantic overtures from village belle  Lajjo  he  instead faces heartbreak following the attack on Babri Masjid by right-wing Hindu extremists. Seeking safety in Bombay  Aayat re-locates there with her father and Bua. The couple are then fated to meet in Scotland during 1999 where Harinder is now an Indian Air Force Pilot and Aayat is a ballet dancer. Both meet and re-kindle their romance  as well as set a date for their wedding. Unfortunately  the Kargil war compels Harinder to return home  and Aayat does not hear from him. Little does she know that he has been gravely injured and his left arm is paralyzed. After being discharged he still hopes to connect with her  returns to Scotland  and is told they have re-located to the United States. Following the events of 9-11  he gets a lead that she may...",
    "year_of_release": 2011,
    "runtime": 161,
    "genres": "Drama|Romance|War",
    "rating": 4.7,
    "cast": "Shahid Kapoor|Sonam Kapoor|Anupam Kher|Supriya Pathak|Aditi Sharma|Manoj Pahwa|Vaibhav Talwar|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BZjU0NzNhNTItNzZmZS00N2QxLWI3YTgtYjRiMzcyNWJiOWRlXkEyXkFqcGdeQXVyNjQ2MjQ5NzM@.jpg"
  },
  {
    "content_id": "tt1839596",
    "title": "Rockstar (2011 film)",
    "summary": "The film follows Janardhan Jhakar in a series of flashbacks and flash forwards  how the small collage boy became an international rock sensation \"Jordan\". To chase his dreams of a rockstar  the wanna-be singer his counseled that music and feelings only come with pain. In the process of which  he meets Heer  a tough on the outside and popular college girl  the both become friends  and as time passes by his dreams of music fade away as they spend time together. When heer moves away  Janardhan is thrown out of his house because of family misunderstandings. As he bides his time at a local mosque  his passion for music comes back to life. He is soon signed by a record label  where he makes more enemies than friends  his tour takes him to Prague  where he is reunited with a married Heer. Things go awry when the two share an intimate moment  and Jordan his deported to India on trespassing charges filed by Heer's husband. Now an angry painful and lonely JORDAN embarks on his journey to become...",
    "year_of_release": 2011,
    "runtime": 159,
    "genres": "Drama|Music|Musical",
    "rating": 7.7,
    "cast": "Ranbir Kapoor|Nargis Fakhri|Shammi Kapoor|Kumud Mishra|Piyush Mishra|Shernaz Patel|Aditi Rao Hydari|Sanjana Sanghi|Shikha Jain|Jaideep Ahlawat|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BOTc3NzAxMjg4M15BMl5BanBnXkFtZTcwMDc2ODQwNw@@.jpg"
  },
  {
    "content_id": "tt1183917",
    "title": "Teen Patti (film)",
    "summary": "Perci Trachtenberg  widely regarded as the world's greatest living mathematician  meets Venkat  a reclusive math genius from India  at a high rolling casino in London. Venkat tells Perci about an equation that could not only change the dialogue on mathematics forever  but one that has already left an indelible impression of guilt - for many painful reasons - on Venkat's life. As it turns out  the reclusive genius Venkat has cracked a theory that could redefine the principles of probability and randomness. However  as with all exceptional knowledge  his equation has its upside - as well as its dark underbelly. Aware that he is on the precipice of an extraordinary discovery  one that could find applications across various sciences  Venkat is encouraged to test his theory in the real world by professor Shantanu  an ambitious colleague of Venkat. Although Venkat has no interest in the money that could come from practicing his equation to crack Teen Patti  (a poker game) he eventually ...",
    "year_of_release": 2010,
    "runtime": 142,
    "genres": "Crime|Drama|Thriller",
    "rating": 4.1,
    "cast": "Amitabh Bachchan|Madhavan|Shraddha Kapoor|Akkash Basnet|Siddharth Kher|Dhruv Ganesh|Saira Mohan|Vaibhav Talwar|Raima Sen|Ben Kingsley|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNzRjN2M2MmQtZTFhNi00Zjk5LWI2MzEtYWFiMDI4NWIyNGJkXkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt1612573",
    "title": "Rokkk",
    "summary": "New Delhi-based Anushka Walia  who lives with her widowed mother  gets a phone from her frightened sister  Ahana Warda  from Goa. Shortly thereafter a male comes on the phone and informs her that Ahana is in the mental asylum after being arrested for killing her husband  Ravi  and sister-in-law  Nishita. Anushka's mother had not approved of her daughter marrying resort-owner  Ravi  and as a result they had not spoken to each other for many years. Anushka  however  departs for Goa and meets with the investigating CBI Officer  Ranvir  but is unable to see nor speak with Ahana who has apparently disappeared. Her investigations will pit her against an unknown entity from the Warda residence  and will entrap her in a horrific nightmarish world from which there is no escape.",
    "year_of_release": 2010,
    "runtime": 180,
    "genres": "Drama|Horror|Mystery",
    "rating": 4.3,
    "cast": "Tanushree Dutta|Udita Goswami|Shaad Randhawa|Sachin Khedekar|Ashwini Kalsekar|Arif Zakaria|Murli Sharma|Nishigandha Wad|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BNGYwZDkxNDQtOGRmNC00OWYxLWEwNDktMDIyOTJiNTM1N2E2XkEyXkFqcGdeQXVyODE5NzE3OTE@.jpg"
  },
  {
    "content_id": "tt1396208",
    "title": "Action Replayy",
    "summary": "After witnessing nothing but acrimony between his bickering Mumbai-based parents  Kishen and Mala Chopra  Bunty refuses to marry his girlfriend  Tanya Gonsalves. He is then introduced to her eccentric grandfather  Professor Anthony Gonsalves  and finds that the latter has invented a time-travel machine. Bunty travels back in time - specifically Holi 1975 - when Mumbai was called Bombay and gets to meet his parents when they were younger. He is shocked to find his buck-toothed wimpy father  and his mother - a goon-like tomboy. Amidst the terror of Raman Raghav  the impending Emergency of former Prime Minister Indira Gandhi  banning of multinationals - including Coca Cola - and the Charisma of superstar Rajesh Khanna - he sets about to try and set things right between not only his bickering parents but also his feuding grandparents. He will soon find out that his mother has another admirer - who will not leave any stone unturned to marry her.",
    "year_of_release": 2010,
    "runtime": 129,
    "genres": "Comedy|Romance|Sci-Fi",
    "rating": 4.2,
    "cast": "Akshay Kumar|Aishwarya Rai Bachchan|Om Puri|Kiron Kher|Neha Dhupia|Rannvijay Singh|Aditya Roy Kapoor|Sudeepa Singh|Randhir Kapoor|Rajpal Yadav|",
    "cover_art": "https://m.media-amazon.com/images/M/MV5BMTMyMDc1MDIwMl5BMl5BanBnXkFtZTcwNDUyMDgwNA@@.jpg"
  }
]