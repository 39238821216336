export const userDetails = [
  {
    user_id: "0aa2fb9a-58b3-4211-b5ee-962f134a4479",
    watch_time_patterns: {
      morning: 4,
      afternoon: 3,
      evening: 1,
    },
    demographics: {
      name: "Sharo",
      age: 31,
      gender: "female",
      location: "Lima, Peru",
      device_types: ["Web", "Mobile", "Desktop"],
    },
  },
  {
    user_id: "4a2dc276-7ca6-4279-a467-1af685a51b95",
    watch_time_patterns: {
      morning: 2,
      afternoon: 4,
      evening: 4,
    },
    demographics: {
      name: "Alice",
      age: 18,
      gender: "male",
      location: "Dublin, Ireland",
      device_types: ["Mobile"],
    },
  },
  {
    user_id: "a62aba82-5fd0-4d96-8f8a-ceb1368e2904",
    watch_time_patterns: {
      morning: 4,
      afternoon: 4,
      evening: 5,
    },
    demographics: {
      name: "Aisha",
      age: 79,
      gender: "female",
      location: "Damascus, Syria",
      device_types: ["Desktop", "Web", "Mobile"],
    },
  },
  {
    user_id: "28db50b7-e8a6-415d-8854-a54f9d0a7886",
    watch_time_patterns: {
      morning: 4,
      afternoon: 5,
      evening: 5,
    },
    demographics: {
      name: "John",
      age: 55,
      gender: "male",
      location: "Los Angeles, USA",
      device_types: ["Web", "Desktop"],
    },
  },
  {
    user_id: "5bb771af-b15e-4b8e-9af3-5fa9721b7e91",
    watch_time_patterns: {
      morning: 2,
      afternoon: 5,
      evening: 4,
    },
    demographics: {
      name: "Umar",
      age: 42,
      gender: "male",
      location: "Muscat, Oman",
      device_types: ["Desktop", "Mobile", "Web"],
    },
  },
  {
    user_id: "90c466e6-bdf4-4075-b5ec-84da6609e250",
    watch_time_patterns: {
      morning: 4,
      afternoon: 5,
      evening: 1,
    },
    demographics: {
      name: "Yuan",
      age: 24,
      gender: "female",
      location: "Beijing, China",
      device_types: ["Mobile"],
    },
  },
  {
    user_id: "65044cbe-a456-473a-8b3e-16535d06f9e7",
    watch_time_patterns: {
      morning: 3,
      afternoon: 4,
      evening: 4,
    },
    demographics: {
      name: "Ali",
      age: 52,
      gender: "male",
      location: "Dubai, UAE",
      device_types: ["Mobile", "Web"],
    },
  },
  {
    user_id: "b1bc5a37-e64a-4a23-b0a1-a3d2c351615c",
    watch_time_patterns: {
      morning: 3,
      afternoon: 1,
      evening: 4,
    },
    demographics: {
      name: "Brun",
      age: 30,
      gender: "female",
      location: "Bandar Seri Begawan, Brunei",
      device_types: ["Web", "Mobile"],
    },
  },
];
