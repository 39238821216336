import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { userDetails } from "./user_profile_data";
import { watchHistory } from "./watch_history_data";
import { searchHistory } from "./search_history_data";
import {
  Input,
  Button,
  Card,
  Col,
  Row,
  CardBody,
  Label,
  FormGroup,
  CardText,
  CardGroup,
  Table,
} from "reactstrap";
import "./App.css";
import MoviesPage from "./movie_list";
import { watchHistoryData } from "./filtered_output_watch_data";
import { searchHistoryData } from "./filtered_output_search_data";

function App() {
  const [submitted, setSubmitted] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [sendData, setSendData] = useState("");
  const [watchHistoryDataFilter, setWatchHistoryDataFilter] = useState([]);
  const [searchHistoryDataFilter, setSearchHistoryDataFilter] = useState([]);
  const [age, setAge] = useState("30");

  const initialValues = {
    age: "30",
    gender: "Female",
    message: "",
  };

  const suggestions = [
    "Interstellar like Science Fiction movies",
    "Horror and Thriller Movies",
    "Who will lift the trophy in today's T20 Cricket Finals",
    "Today is world comedy day, suggest some comedy Movies",
    "Cricket based Movies",
    "Which team is stronger in today's T20 Cricket Finals",
    "Drama is a popular genre, suggest some best dramatic movies",
  ];

  /* const defaultUserDemographics = {
    name: "Sharo",
    age: 31,
    gender: "female",
    location: "Lima, Peru",
    device_types: ["Web", "Mobile", "Desktop"],
  };
 */
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  // Chunk the card data into arrays containing four cards each
  const chunkedWatchCardData = chunkArray(watchHistoryDataFilter, 2);
  const chunkedSearchCardData = chunkArray(searchHistoryDataFilter, 2);

  const validate = (values) => {
    const errors = {};

    /*     if (!values.age) {
      errors.age = "Age is required";
    } */

    if (!values.gender) {
      errors.gender = "Gender is required";
    }

    if (!values.message) {
      errors.message = "LLM Input is required";
    } else if (values.message.length > 250) {
      errors.message = "Message must be less than 250 characters";
    }

    return errors;
  };

  const dataWatchHistoryShow = (user) => {
    if (user) {
      const userHistory = watchHistory.find(
        (val) => val.user_id === user.user_id
      );
      if (userHistory) {
        const userWatchData = userHistory.item_data
          .map((item) =>
            watchHistoryData.find((val2) => val2.content_id === item.content_id)
          )
          .filter(Boolean);
        setWatchHistoryDataFilter(userWatchData);
      } else {
        setWatchHistoryDataFilter([]);
      }
    } else {
      // If no user is selected, set default watch history
      // For example, you can set it to the first user's watch history
      const defaultUser = userDetails[0];
      const defaultUserHistory = watchHistory.find(
        (val) => val.user_id === defaultUser.user_id
      );
      if (defaultUserHistory) {
        const defaultWatchData = defaultUserHistory.item_data
          .map((item) =>
            watchHistoryData.find((val2) => val2.content_id === item.content_id)
          )
          .filter(Boolean);
        setWatchHistoryDataFilter(defaultWatchData);
      } else {
        setWatchHistoryDataFilter([]);
      }
    }
  };

  const dataSearchHistoryShow = (user) => {
    if (user) {
      const userHistory = searchHistory.find(
        (val) => val.user_id === user.user_id
      );
      if (userHistory) {
        const userSearchData = userHistory.item_data
          .map((item) =>
            searchHistoryData.find((val2) => val2.content_id === item)
          )
          .filter(Boolean);
        setSearchHistoryDataFilter(userSearchData);
      } else {
        setSearchHistoryDataFilter([]);
      }
    } else {
      // If no user is selected, set default search history
      // For example, you can set it to the first user's search history
      const defaultUser = userDetails[0];
      const defaultUserHistory = searchHistory.find(
        (val) => val.user_id === defaultUser.user_id
      );
      if (defaultUserHistory) {
        const defaultSearchData = defaultUserHistory.item_data
          .map((item) =>
            searchHistoryData.find((val2) => val2.content_id === item)
          )
          .filter(Boolean);
        setSearchHistoryDataFilter(defaultSearchData);
      } else {
        setSearchHistoryDataFilter([]);
      }
    }
  };

  useEffect(() => {
    document.title = "Content Recommendation System";
    /* dataWatchHistoryShow(null); // Pass null to indicate no user is selected
    dataSearchHistoryShow(null); */
  }, []);

  useEffect(() => {
    if (selectedUser) {
      dataWatchHistoryShow(selectedUser);
      dataSearchHistoryShow(selectedUser);
    }
  }, [selectedUser]);

  const handleReset = () => {
    setSubmitted(false);
    setSelectedUser("");
    setWatchHistoryDataFilter([]);
    setSearchHistoryDataFilter([]);
  };

  //const ageGroups = ["Below 20", "20-30", "30-40", "Above 40"];

  const genderGroups = ["Male", "Female"];

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <>
      {!submitted ? (
        <div className="App-header">
          <Row style={{ marginLeft: "2%", marginRight: "2%" }}>
            <div className="logo">
              <img src="saranyu_logo.png" alt="logo" className="logo" />
            </div>
            <div className="page-title" style={{ marginTop: "30px" }}>
              <span
                style={{
                  color: "#E1E1E1",
                }}
              >
                Content Recommendation System
              </span>
            </div>
          </Row>
          <Row
            className="justify-content-left"
            style={{ marginTop: "2%", marginLeft: "2%", marginRight: "2%" }}
          >
            <Col className="col-4" style={{ alignContent: "center" }}>
              <Card
                style={{
                  color: "#fff",
                  background: "#2A3038",
                  maxHeight: "40vh",
                  overflowY: "auto",
                  height: "40vh",
                  borderRadius: "8px",
                  border: "1px solid #374352",
                }}
                className="custom-scrollbar mb-3"
              >
                <CardBody>
                  <div
                    style={{
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      width: "100%", // Adjust as needed
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "150px", // Adjust as needed
                    }}
                    className="custom-scrollbar"
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        padding: "0.3rem 1rem 0 0.3rem",
                      }}
                    >
                      {userDetails
                        .slice(0, userDetails.length / 2)
                        .map((user, idx) => (
                          <Button
                            key={idx}
                            className={`search-btn-user mb-3 ${
                              selectedUser === user
                                ? "search-btn-user-active"
                                : ""
                            }`}
                            style={{
                              minWidth: "150px", // Adjust as needed
                              flex: "0 0 auto",
                              backgroundColor:
                                selectedUser === user ? "blue" : "gray", // Change color accordingly
                              color: selectedUser === user ? "white" : "black", // Change color accordingly
                            }}
                            onClick={() => {
                              handleUserClick(user);
                              dataWatchHistoryShow(user);
                              dataSearchHistoryShow(user);
                            }}
                          >
                            {user.demographics.name}
                          </Button>
                        ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        padding: "0 1rem 0 0.3rem",
                      }}
                    >
                      {userDetails
                        .slice(userDetails.length / 2)
                        .map((user, idx) => (
                          <Button
                            key={idx}
                            className={`search-btn-user mb-3 ${
                              selectedUser === user
                                ? "search-btn-user-active"
                                : ""
                            }`}
                            style={{
                              minWidth: "150px", // Adjust as needed
                              flex: "0 0 auto",
                              backgroundColor:
                                selectedUser === user ? "blue" : "gray", // Change color accordingly
                              color: selectedUser === user ? "white" : "black", // Change color accordingly
                            }}
                            onClick={() => {
                              handleUserClick(user);
                              dataWatchHistoryShow(user);
                              dataSearchHistoryShow(user);
                            }}
                          >
                            {user.demographics.name}
                          </Button>
                        ))}
                    </div>
                  </div>

                  {
                    selectedUser && (
                      <CardText>
                        <span
                          style={{
                            textAlign: "left",
                            fontStyle: "normal",
                            fontVariant: "normal",
                            fontWeight: "500", // 'medium' corresponds to '500'
                            fontSize: "14px",
                            lineHeight: "21px",
                            fontFamily: "Montserrat",
                            letterSpacing: "0.29px",
                            color: "#E1E1E1",
                            opacity: 1,
                          }}
                        >
                          User Demographics:{" "}
                        </span>
                        <br />
                        <span className="user-demographics">Name: </span>
                        <span className="user-demographics-details">
                          {selectedUser.demographics.name}
                        </span>
                        <br />
                        <span className="user-demographics">Age: </span>
                        <span className="user-demographics-details">
                          {selectedUser.demographics.age}
                        </span>{" "}
                        <br />
                        <span className="user-demographics">Gender: </span>
                        <span className="user-demographics-details">
                          {selectedUser.demographics.gender}
                        </span>
                        <br />
                        <span className="user-demographics">Devices: </span>
                        <span className="user-demographics-details">
                          {selectedUser.demographics.device_types.join(", ")}
                        </span>
                        <br />
                        <span className="user-demographics">Location: </span>
                        <span className="user-demographics-details">
                          {" "}
                          {selectedUser.demographics.location}
                        </span>
                      </CardText>
                    ) /*  : (
                            <CardText>
                              <h5>User Demographics: Default</h5>
                              <span>
                                Name: {defaultUserDemographics.name}
                              </span>{" "}
                              <br />
                              <span>
                                Age: {defaultUserDemographics.age}
                              </span>{" "}
                              <br />
                              <span>
                                Gender : {defaultUserDemographics.gender}
                              </span>{" "}
                              <br />
                              <span>
                                Devices :{" "}
                                {defaultUserDemographics.device_types.join(
                                  ", "
                                )}
                              </span>{" "}
                              <br />
                              <span>
                                Location : {defaultUserDemographics.location}
                              </span>
                            </CardText>
                          )*/
                  }
                </CardBody>
              </Card>
              <Card
                style={{
                  color: "#fff",
                  background: "#2A3038",
                  maxHeight: "36vh",
                  overflowY: "auto",
                  height: "36vh",
                  borderRadius: "8px",
                  border: "1px solid #374352",
                }}
                className="custom-scrollbar"
              >
                <CardBody>
                  <div>
                    <Formik
                      initialValues={initialValues}
                      validate={validate}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          console.log(selectedUser);
                          /* const newFormEntry = { ...values };
                          setFormEntries([...formEntries, newFormEntry]);*/
                          if (!selectedUser) {
                            // If no user is selected, set default values
                            const userData = {
                              user_id: "0aa2fb9a-58b3-4211-b5ee-962f134a4479",
                              trigger_llm: true,
                              demographics: {
                                name: "Sharo",
                                age: 31,
                                gender: "female",
                                location: "Lima, Peru",
                                device_types: ["Web", "Mobile", "Desktop"],
                              },
                              llm_additional_prompt: values.message,
                            };
                            const data = JSON.stringify(userData, null, 2);
                            //alert(data);
                            setSendData(data);
                            setSubmitted(true);
                          } else {
                            var userData = {
                              user_id: selectedUser.user_id,
                              trigger_llm: true,
                              demographics: {
                                name:
                                  selectedUser.demographics?.name || "Sharo",
                                age: selectedUser.demographics?.age || 31,
                                gender:
                                  selectedUser.demographics?.gender || "female",
                                location:
                                  selectedUser.demographics?.location ||
                                  "Lima, Peru",
                                device_types: selectedUser.demographics
                                  ?.device_types || [
                                  "Web",
                                  "Mobile",
                                  "Desktop",
                                ],
                              },
                              llm_additional_prompt: values.message,
                            };

                            if (selectedUser.demographics.name === "Brun") {
                              console.log(
                                "Hello",
                                parseInt(age),
                                values.gender
                              );
                              userData.demographics.age = parseInt(age); // Override age for Brun
                              userData.demographics.gender = values.gender; // Override gender for Brun
                            }

                            const data = JSON.stringify(userData, null, 2);
                            //alert(data);
                            setSendData(data);
                            setSubmitting(false);
                            setSubmitted(true);
                          }
                        }, 400);
                      }}
                    >
                      {({ isSubmitting, handleChange, values }) => (
                        <Form>
                          {selectedUser &&
                            selectedUser.demographics.name === "Brun" && (
                              <>
                                <FormGroup>
                                  <Label>Age: {age}</Label>
                                  <div className="checkbox-group">
                                    <div
                                      className="form-check"
                                      style={{
                                        display: "inline",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <Field
                                        as={Input}
                                        type="range"
                                        min="15"
                                        max="85"
                                        name="age"
                                        id="age"
                                        value={age}
                                        onChange={(e) => setAge(e.target.value)}
                                        className="form-check-input"
                                      />
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name="age"
                                    component="div"
                                    className="text-danger"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label>Gender</Label>
                                  <div className="checkbox-group">
                                    {genderGroups.map((genderGroup, idx) => (
                                      <div
                                        key={idx}
                                        className="form-check"
                                        style={{
                                          display: "inline",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <Field
                                          as={Input}
                                          type="radio"
                                          name="gender"
                                          id={`gender${idx}`}
                                          value={genderGroup}
                                          className="form-check-input"
                                        />
                                        <Label
                                          for={`gender${idx}`}
                                          className="form-check-label"
                                        >
                                          {genderGroup}
                                        </Label>
                                      </div>
                                    ))}
                                  </div>
                                  <ErrorMessage
                                    name="gender"
                                    component="div"
                                    className="text-danger"
                                  />
                                </FormGroup>
                              </>
                            )}

                          <FormGroup>
                            <Label
                              for="message"
                              style={{
                                textAlign: "left",
                                fontStyle: "normal",
                                fontVariant: "normal",
                                fontWeight: "normal",
                                fontSize: "17px",
                                lineHeight: "26px",
                                fontFamily: "Montserrat",
                                letterSpacing: "0.43px",
                                color: "#E1E1E1",
                                opacity: 1,
                              }}
                            >
                              LLM Input
                            </Label>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Field
                                as={Input}
                                type="text"
                                name="message"
                                id="message"
                                className="form-control custom-scrollbar"
                                placeholder="Enter a Message"
                                value={values.message}
                                onChange={handleChange}
                                style={{
                                  height: "40px",
                                  width: "200px",
                                  background: "#2A3038",
                                  color: "#E1E1E1",
                                  border: "1px solid #374352",
                                }}
                              />
                              <Button
                                type="submit"
                                className="search-btn-reset mb-3"
                                disabled={isSubmitting}
                                style={{
                                  width: "25%",
                                  marginLeft: "1rem",
                                  marginTop: "15px",
                                }}
                              >
                                Search
                              </Button>
                            </div>
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                          <p
                            style={{
                              textAlign: "left",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "normal",
                              fontSize: "17px",
                              lineHeight: "26px",
                              fontFamily: "Montserrat",
                              letterSpacing: "0.43px",
                              color: "#E1E1E1",
                              opacity: 1,
                            }}
                          >
                            Example Prompts
                          </p>
                          <div
                            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                            className="custom-scrollbar"
                          >
                            <div style={{ display: "flex" }}>
                              {suggestions.map((suggestion, id) => (
                                <div
                                  key={id}
                                  className="mb-2"
                                  style={{
                                    background: "#374352",
                                    //border: "1px solid white",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    marginRight: "0.5rem", // Adjust spacing
                                    textAlign: "center",
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    fontWeight: "500", // 'medium' corresponds to '500'
                                    fontSize: "15px",
                                    lineHeight: "21px",
                                    fontFamily: "Montserrat",
                                    letterSpacing: "0.32px",
                                    color: "#E1E1E1",
                                    opacity: 1,
                                  }}
                                >
                                  <span
                                    onClick={() =>
                                      handleChange({
                                        target: {
                                          name: "message",
                                          value: `${suggestion}`,
                                        },
                                      })
                                    }
                                  >
                                    {suggestion}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <div
              className="col-8"
              style={{
                border: "1px solid #374352",
                padding: "0",
                borderRadius: "8px",
              }}
            >
              <table
                className="col-12 table-style"
                style={{
                  margin: "0%",
                  borderCollapse: "collapse",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <tbody /* style={{ border: "1px solid #374352" }} */>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "left",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "normal",
                        fontSize: "17px",
                        lineHeight: "26px",
                        fontFamily: "Montserrat",
                        letterSpacing: "0.43px",
                        color: "#E1E1E1",
                        opacity: 1,
                        border: "1px solid #374352",
                        borderTopLeftRadius: "8px !important",
                        padding: "12px 0px 10px 14px",
                      }}
                    >
                      User Related Data <br />
                      {selectedUser && (
                        <span
                          style={{
                            textAlign: "left",
                            fontStyle: "normal",
                            fontVariant: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "21px",
                            fontFamily: "Montserrat",
                            letterSpacing: "0.29px",
                            color: "#A7A6A4",
                            opacity: 1,
                          }}
                        >
                          {selectedUser?.demographics?.name} related data
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="col-6"
                      style={{
                        textAlign: "left",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "normal",
                        fontSize: "17px",
                        lineHeight: "26px",
                        fontFamily: "Montserrat",
                        letterSpacing: "0.43px",
                        color: "#E1E1E1",
                        opacity: 1,
                        border: "1px solid #374352",
                      }}
                    >
                      Watch History
                    </td>
                    <td
                      className="col-6"
                      style={{
                        textAlign: "left",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "normal",
                        fontSize: "17px",
                        lineHeight: "26px",
                        fontFamily: "Montserrat",
                        letterSpacing: "0.43px",
                        color: "#E1E1E1",
                        opacity: 1,
                        border: "1px solid #374352",
                      }}
                    >
                      Search History
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="col-6"
                      style={{
                        textAlign: "left",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "500", // 'medium' corresponds to '500'
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontFamily: "Montserrat",
                        letterSpacing: "0.22px",
                        color: "#E1E1E1",
                        opacity: 1,
                        border: "1px solid #374352",
                      }}
                    >
                      <Card
                        style={{
                          color: "#fff",
                          background: "#2A3038",
                          maxHeight: "52vh",
                          overflowY: "auto",
                          height: "52vh",
                          border: "1px solid #2A3038",
                        }}
                        className="custom-scrollbar"
                      >
                        <CardBody>
                          <CardGroup>
                            <div>
                              {chunkedWatchCardData.map((row, index) => (
                                <Row key={index}>
                                  {row.map((movie, cardIndex) => (
                                    <Col key={cardIndex} className="col-12">
                                      <p
                                        style={{
                                          background: "#2A3038",
                                          color: "#fff",
                                          maxHeight: "25vh",
                                          overflowY: "auto",
                                          height: "25vh",
                                          //cursor: "pointer", // Make the card clickable
                                        }}
                                        className="custom-scrollbar equal-height"
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            width="25%"
                                            src={movie.cover_art}
                                            alt="movie poster"
                                            style={{ marginRight: "5px" }}
                                          />
                                          <span
                                            style={{
                                              display: "inline",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {movie.title}
                                            <br />
                                            <span
                                              style={{
                                                display: "inline",
                                                color: "rgb(158 171 183)",
                                              }}
                                            >
                                              {movie.year_of_release} ‧{" "}
                                            </span>{" "}
                                            <span className="text-muted">
                                              {movie.genres} ‧{" "}
                                            </span>
                                            <span className="text-muted">
                                              {movie.rating}
                                            </span>
                                            <br />
                                            <span
                                              className="text-muted"
                                              style={{
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                overflow: "hidden",
                                                WebkitLineClamp: 3, // Limiting to 3 lines
                                              }}
                                            >
                                              {movie.summary}
                                            </span>
                                          </span>
                                        </span>
                                      </p>
                                      {/* <hr
                                        style={{
                                          backgroundColor: "#ffffff4d",
                                          width: "100%",
                                        }}
                                      ></hr> */}
                                    </Col>
                                  ))}
                                </Row>
                              ))}
                            </div>
                          </CardGroup>
                        </CardBody>
                      </Card>
                    </td>
                    <td
                      className="col-6"
                      style={{
                        textAlign: "left",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "500", // 'medium' corresponds to '500'
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontFamily: "Montserrat",
                        letterSpacing: "0.22px",
                        color: "#E1E1E1",
                        opacity: 1,
                      }}
                    >
                      <Card
                        style={{
                          color: "#fff",
                          background: "#2A3038",
                          maxHeight: "52vh",
                          overflowY: "auto",
                          height: "52vh",
                          border: "1px solid #2A3038",
                        }}
                        className="custom-scrollbar"
                      >
                        <CardBody>
                          <CardGroup>
                            <div>
                              {chunkedSearchCardData.length === 0 ? (
                                <p style={{ marginTop: "100%" }}>
                                  {/* Please select a user */}
                                </p>
                              ) : (
                                chunkedSearchCardData.map((row, index) => (
                                  <Row key={index}>
                                    {row.map((movie, cardIndex) => (
                                      <Col key={cardIndex} className="col-12">
                                        <p
                                          style={{
                                            background: "#2A3038",
                                            color: "#fff",
                                            maxHeight: "25vh",
                                            overflowY: "auto",
                                            height: "25vh",
                                            //cursor: "pointer", // Make the card clickable
                                          }}
                                          className="custom-scrollbar equal-height"
                                        >
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              width="25%"
                                              src={movie.cover_art}
                                              alt="movie poster"
                                              style={{ marginRight: "5px" }}
                                            />
                                            <span
                                              style={{
                                                display: "inline",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              {movie.title}
                                              <br />
                                              <span
                                                style={{
                                                  display: "inline",
                                                  color: "rgb(158 171 183)",
                                                }}
                                              >
                                                {movie.year_of_release}
                                              </span>{" "}
                                              <span className="text-muted">
                                                {movie.genres}
                                              </span>
                                              <br />
                                              <span
                                                className="text-muted"
                                                style={{
                                                  display: "-webkit-box",
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                  WebkitLineClamp: 3, // Limiting to 3 lines
                                                }}
                                              >
                                                {movie.summary}
                                              </span>
                                            </span>
                                          </span>
                                        </p>
                                        {/* <hr
                                          style={{
                                            backgroundColor: "#ffffff4d",
                                            width: "100%",
                                          }}
                                        ></hr> */}
                                      </Col>
                                    ))}
                                  </Row>
                                ))
                              )}
                            </div>
                          </CardGroup>
                        </CardBody>
                      </Card>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
        </div>
      ) : (
        <MoviesPage onReset={handleReset} sendData={sendData} />
      )}
    </>
  );
}

export default App;
