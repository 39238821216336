export const searchHistory = [
  {
    user_id: "0aa2fb9a-58b3-4211-b5ee-962f134a4479",
    item_data: [
      "tt3483646",
      "tt1363125",
      "tt3449292",
      "tt3726012",
      "tt5151622",
    ],
  },
  {
    user_id: "4a2dc276-7ca6-4279-a467-1af685a51b95",
    item_data: [
      "tt3836958",
      "tt4556848",
      "tt1620719",
      "tt8202612",
      "tt6826438",
    ],
  },
  {
    user_id: "a62aba82-5fd0-4d96-8f8a-ceb1368e2904",
    item_data: [
      "tt3309662",
      "tt7722258",
      "tt5502766",
      "tt4087770",
      "tt7526836",
    ],
  },
  {
    user_id: "28db50b7-e8a6-415d-8854-a54f9d0a7886",
    item_data: [
      "tt3337550",
      "tt8785426",
      "tt2691010",
      "tt6642396",
      "tt3431756",
    ],
  },
  {
    user_id: "5bb771af-b15e-4b8e-9af3-5fa9721b7e91",
    item_data: [
      "tt3840534",
      "tt2023645",
      "tt1781838",
      "tt2762334",
      "tt8291224",
    ],
  },
  {
    user_id: "90c466e6-bdf4-4075-b5ec-84da6609e250",
    item_data: [
      "tt1172587",
      "tt3173910",
      "tt6170954",
      "tt1918965",
      "tt3359290",
    ],
  },
  {
    user_id: "65044cbe-a456-473a-8b3e-16535d06f9e7",
    item_data: [
      "tt1773015",
      "tt2104937",
      "tt1734110",
      "tt3380264",
      "tt3859980",
    ],
  },
  {
    user_id: "b1bc5a37-e64a-4a23-b0a1-a3d2c351615c",
    item_data: [
      "tt4640206",
      "tt2976182",
      "tt4707940",
      "tt3142232",
      "tt1373156",
    ],
  },
];

export const searchHistoryContentIDS = Array.from(
  new Set(searchHistory.map((user) => user.item_data).flat())
);
